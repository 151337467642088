/* eslint-disable no-console */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-undef */
import axios from 'axios'
import * as Sentry from "@sentry/react";

export function requestCart(config, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_WEB_API_URL}cart/new`, config, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export function requestRenewCart(config, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_WEB_API_URL}cart/renew`, config, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function updateSuccessfullCart(cart, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(
          `${process.env.REACT_APP_WEB_API_URL}cart/${cart}/payment/success`,
        { cart_id: cart, recurring_id: cart },
        {
          headers: {
            email: user.email,
            hash: user.authToken,
          },
        }
      )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function updateFailedCart(order) {
  return new Promise((resolve, reject) => {
    axios
      .post(
          `${process.env.REACT_APP_WEB_API_URL}cart/${order.cart.cart_id}/payment/failed`,
        { cart_id: order.cart.cart_id },
        {
          headers: {
            email: order.user.email,
            hash: order.user.authToken,
          },
        }
      )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function createServerFromCart(cart, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(
          `${process.env.REACT_APP_WEB_API_URL}cart/${cart}/process`,
        { cart_id: cart },
        {
          headers: {
            email: user.email,
            hash: user.authToken,
          },
        }
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function paymentRequest(order) {
  console.log('PURCHASE ORDER OBJ:', order)
  return new Promise((resolve, reject) => {
    if (typeof Windows !== 'undefined') {
      const store = Windows.Services.Store.StoreContext.getDefault()

      if (order.storeId) {
        const { storeId } = order
        store.requestPurchaseAsync(storeId.storeId).done((result) => {
          console.log(result.extendedError)
          switch (result.status) {
            case Windows.Services.Store.StorePurchaseStatus.alreadyPurchased:
              Sentry.captureMessage('The current user has already purchased the specified app or add-on.');
              reject({
                statusCode: Windows.Services.Store.StorePurchaseStatus.alreadyPurchased,
                message: {
                  message: 'purchase-already-purchased',
                  level: 'error',
                },
              })
              break
            case Windows.Services.Store.StorePurchaseStatus.networkError:
              Sentry.captureMessage('The purchase request did not succeed because of a network connectivity error.');
              reject({
                statusCode: Windows.Services.Store.StorePurchaseStatus.networkError,
                message: {
                  message: 'purchase-network-error',
                  level: 'error',
                },
              })
              break
            case Windows.Services.Store.StorePurchaseStatus.notPurchased:
              Sentry.withScope(function (scope) {
                scope.setExtra('microsoftResult', result.extendedError);
                Sentry.captureMessage('The purchase request did not succeed.', {extra: result});
              })
              reject({
                statusCode: Windows.Services.Store.StorePurchaseStatus.notPurchased,
                message: {
                  message: 'purchase-not-complete',
                  level: 'error',
                },
              })
              break
            case Windows.Services.Store.StorePurchaseStatus.serverError:
              Sentry.withScope(function (scope) {
                scope.setExtra('microsoftResult', result.extendedError);
                Sentry.captureMessage('The purchase request did not succeed because of a server error returned by the Microsoft Store.', {extra: result});
              });
              reject({
                statusCode: Windows.Services.Store.StorePurchaseStatus.serverError,
                message: {
                  message: 'purchase-server-error',
                  level: 'error',
                },
              })
              break
            case Windows.Services.Store.StorePurchaseStatus.succeeded:
              resolve({
                data: result,
                message: {
                  message: 'purchase-successfully-purchased',
                  level: 'success',
                },
              })
              break
            default:
              Sentry.withScope(function (scope) {
                scope.setExtra('microsoftResult', result.extendedError);
                Sentry.captureMessage(`Purchase unknown error: ${result.status}`);
              })
              reject({
                statusCode: -1,
                message: {
                  message: 'purchase-unknown-error',
                  level: 'error',
                },
              })
          }
        })
      }
    } else {
      reject({
        statusCode: -1,
        message: {
          message: 'purchase-no-store-id',
          level: 'error',
        },
      })
    }
  })
}
