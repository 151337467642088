import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Flex } from 'rebass/styled-components'

import { Layout } from '../../layouts'
import { ServerBox, ServerCard, Portal, ConfigurationForm } from '../../components'

import { buttons, stoppedButtons, expiredButtons } from './controls'
import { setControls, updateControls, disableControls } from '../../redux/controls/actions'
import {
  getMyServer,
  selectCurrentServer,
  restartServer,
  stopServer,
  startServer,
} from '../../redux/server/actions'
import {
  getServers,
  getSelectedServer,
  initServerRestart,
  initServerStart,
  initServerStop,
  isFetchingSettings,
} from '../../redux/server/selectors'

import { startRenewCartProcess } from '../../redux/cart/actions'
import { getProductListings, initRenew } from '../../redux/cart/selectors'

import { getUser } from '../../redux/user/selectors'
import { getPortalVisibility, getConfigurationVisibility } from '../../redux/ui/selectors'

import { messengerAction } from '../../redux/messages/actions'

const Dashboard = () => {
  const dispatch = useDispatch()
  const focusRef = useRef()
  const portalRef = useRef()

  const userSelector = useSelector(getUser)
  const [authToken, setAuthToken] = useState(null)
  const [currentUser, setCurrentUser] = useState(userSelector)

  useEffect(() => {
    return () => dispatch({ type: 'HIDE_PORTAL' })
  }, [dispatch])

  useEffect(() => {
    setCurrentUser(userSelector)
    if (userSelector.authToken !== authToken) setAuthToken(userSelector.authToken)
  }, [userSelector, authToken])

  const getMyServers = useCallback((user) => dispatch(getMyServer(user)), [dispatch])
  useEffect(() => {
    if (authToken) getMyServers(currentUser)
  }, [getMyServers, authToken, currentUser])

  const setButtons = useCallback(() => dispatch(setControls(buttons)), [dispatch])
  const setStoppedButtons = useCallback(() => dispatch(setControls(stoppedButtons)), [dispatch])
  const setExpiredButtons = useCallback(() => dispatch(setControls(expiredButtons)), [dispatch])
  const disableButtons = useCallback(() => dispatch(disableControls()), [dispatch])
  const updateButtons = useCallback((obj) => dispatch(updateControls(obj)), [dispatch])

  // useEffect(() => {
  //   setButtons()
  //   return () => resetButtons()
  // }, [setButtons, resetButtons])

  const configurationSelector = useSelector(getConfigurationVisibility)
  const [configuration, setConfiguration] = useState(configurationSelector)

  useEffect(() => {
    setConfiguration(configurationSelector)
  }, [configurationSelector])

  const serverSelector = useSelector(getSelectedServer)
  const [selected, setSelected] = useState(serverSelector)

  useEffect(() => {
    if (serverSelector) {
      setSelected(serverSelector)
      const { state } = serverSelector

      // setButtons()
      switch (state) {
        case 'running':
          setButtons()
          break
        case 'stopped':
          setStoppedButtons()
          break
        // case 'stopping':
        //   setStoppedButtons()
        //   disableButtons()
        //   break
        case 'unknown':
          setStoppedButtons()
          break
        case 'suspended':
          setExpiredButtons()
          break
        default:
          setButtons()
          disableButtons()
          break
      }
    }
  }, [
    serverSelector,
    setButtons,
    setExpiredButtons,
    updateButtons,
    setStoppedButtons,
    disableButtons,
  ])

  const portalSelector = useSelector(getPortalVisibility)
  const [openPortal, setOpenPortal] = useState(portalSelector)

  useEffect(() => {
    if (portalSelector && (selected?.state !== 'stopped' || selected?.state === 'stopping')) {
      dispatch(
        messengerAction(
          { message: 'no-config-allowed', level: 'error' },
          'noConfigurationOnOnlineServerForDashboard'
        )
      )
      dispatch({ type: 'HIDE_PORTAL' })
    } else if (selected?.state === 'stopped' || selected?.state === 'stopping') {
      setOpenPortal(portalSelector)
    }
    if (!portalSelector && selected) {
      const { state } = selected
      // setButtons()
      switch (state) {
        case 'running':
          setButtons()
          break
        case 'stopped':
          setStoppedButtons()
          break
        case 'unknown':
          setStoppedButtons()
          break
        case 'suspended':
          setExpiredButtons()
          break
        default:
          break
      }
    }
    if (typeof window !== 'undefined') {
      const { TVJS } = window
      TVJS.DirectionalNavigation.focusRoot = document.getElementById(
        portalSelector ? 'portal-root' : 'servers'
      )
    }
  }, [portalSelector, setButtons, setStoppedButtons, setExpiredButtons, selected, dispatch])

  const serversSelector = useSelector(getServers)
  const [servers, setServers] = useState(serversSelector)

  useEffect(() => {
    setServers(serversSelector)
  }, [serversSelector])

  // Server Controls
  const restartSelector = useSelector(initServerRestart)
  useEffect(() => {
    if (restartSelector) {
      dispatch(restartServer(selected, currentUser))
      // setSelected({ ...selected, state: 'restarting' })
    }
  }, [restartSelector, currentUser, selected, dispatch])

  const stopSelector = useSelector(initServerStop)
  useEffect(() => {
    if (stopSelector) {
      dispatch(stopServer(selected, currentUser))
    }
  }, [stopSelector, currentUser, selected, dispatch])

  const startSelector = useSelector(initServerStart)
  useEffect(() => {
    if (startSelector) {
      dispatch(startServer(selected, currentUser))
    }
  }, [startSelector, currentUser, selected, dispatch])

  // Cart Controls
  const productListings = useSelector(getProductListings)
  const renewSelector = useSelector(initRenew)
  useEffect(() => {
    if (renewSelector) {
      dispatch(startRenewCartProcess(selected, currentUser, productListings))
    }
  }, [renewSelector, productListings, currentUser, selected, dispatch])

  const settingsSelector = useSelector(isFetchingSettings)
  useEffect(() => {
    if (settingsSelector) {
      const { setting_url: settingUrl } = selected
      if (typeof Windows !== 'undefined') {
        // eslint-disable-next-line no-undef
        Windows.System.Launcher.launchUriAsync(
          // eslint-disable-next-line no-undef
          new Windows.Foundation.Uri(settingUrl)
        ).then((success) => {
          if (success) {
            dispatch({ type: 'myserver/GET_SETTINGS_LINK_SUCCESS' })
          } else {
            dispatch({ type: 'myserver/GET_SETTINGS_LINK_FAILURE' })
          }
        })
      } else {
        dispatch({ type: 'myserver/GET_SETTINGS_LINK_FAILURE' })
      }
    }
  }, [settingsSelector, dispatch, selected])

  return (
    <Layout>
      <Flex id="servers" sx={{ padding: '0 100px 100px 100px', flexWrap: 'wrap' }} ref={focusRef}>
        {servers &&
          servers.length > 0 &&
          servers.map((server, index) => {
            const { ip_and_port: ipData } = server
            return (
              <Fragment key={server.id}>
                {ipData && (
                  <ServerBox
                    width={[1, 1, 1 / 2]}
                    key={`server-card-${server.id}`}
                    id={`server-card-${server.id}`}
                    tabIndex={index}
                    className="focusable"
                    onFocus={() => {
                      dispatch(selectCurrentServer(server))
                    }}
                    selected={selected && server.id === selected.id}
                  >
                    <ServerCard
                      loading={false}
                      server={server}
                      isSelected={selected && server.id === selected.id}
                    />
                  </ServerBox>
                )}
              </Fragment>
            )
          })}
      </Flex>
      <Portal
        isOpen={openPortal && selected !== undefined}
        displayContent={selected && selected.product.images.cover}
        portal={portalRef}
      >
        <>{configuration && <ConfigurationForm server={selected} portal={portalRef} />}</>
      </Portal>
    </Layout>
  )
}

export default Dashboard
