// styled-components based extension
import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Container = styled(Flex)(
  (props) => css`
    flex-grow: 1;
    height: 100vh;
    background-color: ${props.theme.colors.gradientFrom};
    background: linear-gradient(
      to right,
      ${props.theme.colors.gradientFrom} 0%,
      ${props.theme.colors.gradientTo} 100%
    );
  `
)

Container.defaultProps = {
  mx: 0,
  px: 0,
}

export default Container
