import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import PortalContent from './PortalContent'

const Portal = (props) => {
  const { ariaLabel, children, role, isOpen, displayContent, portal } = props

  const toggleScrollLock = () => {
    const el = document.querySelector('html')
    const style = window.getComputedStyle ? getComputedStyle(el, null) : el.currentStyle
    // eslint-disable-next-line no-unused-expressions
    style.overflow === 'hidden' ? (el.style.overflow = 'visible') : (el.style.overflow = 'hidden')
  }

  useEffect(() => {
    toggleScrollLock()
    return () => {
      toggleScrollLock()
    }
  }, [isOpen])

  return (
    <>
      {isOpen && (
        <PortalContent
          displayContent={displayContent}
          ariaLabel={ariaLabel}
          content={children}
          role={role}
          portal={portal}
        />
      )}
    </>
  )
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  isOpen: PropTypes.bool,
  displayContent: PropTypes.node,
  portal: PropTypes.shape({
    current: PropTypes.string,
  }),
}

Portal.defaultProps = {
  ariaLabel: '',
  role: 'dialog',
  isOpen: false,
  displayContent: '',
  portal: { current: '' },
}

export default Portal
