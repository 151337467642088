import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Wrapper = styled(Flex)(
  () => css`
    position: relative;
    padding: 5px 0 5px 0;
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scroll-margin: 0;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start end;
    ::-webkit-scrollbar {
      display: none;
    }
  `
)

export default Wrapper
