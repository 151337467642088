import styled, { css } from 'styled-components'
import { Image } from 'rebass/styled-components'

const Indicator = styled(Image)(
  (props) => css`
    position: absolute;
    top: -10px;
    right: -10px;
    /* top: 5px;
    right: 5px; */
    width: 28px;
    height: 28px;
    background-color: ${props.theme.colors.brand};
    border-radius: 50%;
    border: none !important;
  `
)

export default Indicator
