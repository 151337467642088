import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation, Trans } from 'react-i18next'

import resource from '../../assets/translations/en.json'

const Toast = ({ message }) => {
  const { i18n } = useTranslation()

  i18n.addResources('en', 'messages', resource)
  return <Trans i18nKey={`messages:${message}`} />
}

Toast.defaultProps = {
  message: 'missing',
}

Toast.propTypes = {
  message: PropTypes.string,
}

export default Toast
