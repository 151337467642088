import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Flex, Box } from 'rebass/styled-components'
import { useSpring, animated } from 'react-spring'

import Button from '../styled/Button'
import Title from '../styled/Title'

const TimedButton = ({ button, color, disabled, isPressed, isReleased, cb }) => {
  const [pressed, setPressed] = useState(isPressed)
  const [fullfilled, setFullfilled] = useState(false)
  const [released, setReleased] = useState(true)

  const handleCallback = () => {
    if (!fullfilled) {
      setFullfilled(true)
      cb()
    }
  }

  // cb() is a callback function called when animation is fullfilled
  const animation = useSpring({
    height: '36px',
    strokeDashoffset: pressed ? 260 : 380,
    // onFrame: (val) => console.log(val) && val.strokeDashoffset === 260 && cb(),
    onRest: (val) => val.strokeDashoffset === 260 && handleCallback(),
    config: {
      mass: 1,
      tension: 280,
      friction: 24,
      clamp: true,
      precision: 0.1,
      duration: button.timed || 1000,
    },
  })

  useEffect(() => {
    setPressed(isPressed)
  }, [isPressed])

  useEffect(() => {
    setReleased(isReleased)
  }, [isReleased])

  useEffect(() => {
    if (released) setFullfilled(false)
  }, [released])

  return (
    <Flex flexGrow={1}>
      <Box
        sx={{
          position: 'absolute',
          width: '36px',
          height: '36px',
        }}
      >
        <animated.svg
          viewBox="0 0 36 36"
          fill="none"
          stroke={color.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="360"
          strokeWidth="3"
          style={animation}
        >
          <circle
            transform="translate(18.000000, 18.000000) rotate(-90.000000) translate(-18.00000, -18.00000)"
            cx="18px"
            cy="18px"
            r="16px"
          />
        </animated.svg>
      </Box>
      <Button
        sx={{
          width: '36px',
          height: '36px',
        }}
        lowerOpacity={disabled}
        color={color.color}
        bg={color.backgroundColor}
      >
        {button.char.toUpperCase()}
      </Button>
      <Flex alignItems="center">
        <Title lowerOpacity={disabled} color="lightGrey">
          {button.title.toUpperCase()}
        </Title>
      </Flex>
    </Flex>
  )
}

TimedButton.propTypes = {
  button: PropTypes.shape({
    action: PropTypes.string,
    char: PropTypes.string,
    keyMap: PropTypes.number,
    timed: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  color: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  isPressed: PropTypes.bool,
  isReleased: PropTypes.bool,
  cb: PropTypes.func,
}

TimedButton.defaultProps = {
  disabled: false,
  isPressed: false,
  isReleased: false,
  cb: () => true,
}

export default TimedButton
