const buttons = [
  {
    char: 'a',
    title: 'select',
    action: 'AUTH_SELECT_CURRENT',
    keyMap: 195,
  },
]

export default buttons

// {
//   char: 'x',
//   title: 'Registrieren',
//   timed: 1000,
//   action: 'AUTH_REGISTER',
//   keymap: 197,
// },
