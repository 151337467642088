import React from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'

import { Flex } from 'rebass/styled-components'
import ButtonControl from './components/ButtonControl'

const ControlGroup = ({ buttons }) => {
  return (
    <Flex>
      {buttons.map((button) => (
        <ButtonControl button={button} key={uniqueId()} />
      ))}
    </Flex>
  )
}

ControlGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ControlGroup
