import styled, { css } from 'styled-components'
import { Box } from 'rebass/styled-components'

const ErrorBox = styled(Box)(
  (props) => css`
    background-color: ${props.theme.colors.error};
    color: ${props.theme.colors.text};
    padding: 0.5rem 0.75rem;
    margin-top: 0.5rem;
    border-radius: 6px;
  `
)

export default ErrorBox
