import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Dashboard, Rent, Login, Splash, PortalGate, Account } from './routes'

const MainRoutes = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/splash" component={Splash} />
    <Route path="/login" component={Login} />
    <Route path="/portal" component={PortalGate} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/rent" component={Rent} />
    <Route path="/account" component={Account} />
  </Switch>
)

export default MainRoutes
