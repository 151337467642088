import styled, { css } from 'styled-components'

const Container = styled.div(
  () => css`
    position: fixed;
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1010;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    padding: 0;
  `
)

export default Container
