import {
  GET_GAMES_FAILURE,
  GET_GAMES_REQUEST,
  GET_GAMES_SUCCESS,
  GET_LOCATION_FAILURE,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  GET_CONFIGURATION_FAILURE,
  GET_CONFIGURATION_REQUEST,
  GET_CONFIGURATION_SUCCESS,
  CLEAR_REDUCER,
} from './actions'

/** Initial State */
const initialRentState = {
  isFetching: false,
  fulfilled: false,
  location: undefined,
  listOfLocations: [],
  game: undefined,
  configuration: undefined,
  renewData: null,
}

function rentReducer(state = initialRentState, action) {
  switch (action.type) {
    case CLEAR_REDUCER:
      return { ...state, location: undefined, game: undefined, configuration: undefined }
    case GET_GAMES_REQUEST:
      return { ...state, isFetching: true }
    case GET_GAMES_SUCCESS:
      return { ...state, listOfGames: action.payload.payload, isFetching: false, fulfilled: true }
    case GET_GAMES_FAILURE:
      return { ...state, isFetching: false }
    case GET_LOCATION_REQUEST:
      return { ...state, isFetching: true }
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listOfLocations: [...action.payload.payload],
        fulfilled: true,
      }
    case GET_LOCATION_FAILURE:
      return { ...state, isFetching: false }
    case GET_CONFIGURATION_REQUEST:
      return { ...state, isFetching: true }
    case GET_CONFIGURATION_SUCCESS: {
      return { ...state, isFetching: false, configuration: action.payload }
    }
    case GET_CONFIGURATION_FAILURE:
      return { ...state, isFetching: false }
    default:
      return state
  }
}

export default rentReducer
