/* eslint-disable no-undef */
import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
// MSAL imports
import { MsalProvider } from '@azure/msal-react'
import CustomNavigationClient from '../libs/NavigationClient'

import theme, { GlobalStyle } from '../styled/theme'
import MainRoutes from '../routes/MainRoutes'

import setupReduxStore, { history } from '../redux/store'
import { Splash } from '../views'
import '../config/i18n'

import { MsalEventWrapper } from '../components'

const store = setupReduxStore()

const App = ({ pca }) => {
  const navigationClient = new CustomNavigationClient(history)
  pca.setNavigationClient(navigationClient)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { TVJS } = window
      // TVJS.DirectionalNavigation.focusRoot = document.getElementById('currentFocus');
      TVJS.DirectionalNavigation.focusableSelectors.push('.focusable')
    }
  }, [])

  useEffect(() => {
    if (typeof Windows !== 'undefined') {
      // eslint-disable-next-line no-undef
      const { ViewManagement } = Windows.UI
      // const ApplicationView = ViewManagement.ApplicationView
      const { ApplicationViewScaling } = ViewManagement
      // eslint-disable-next-line no-undef
      if (ApplicationViewScaling !== 'undefined')
        ApplicationViewScaling.trySetDisableLayoutScaling(true)
    }
  }, [])

  return (
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <MsalEventWrapper pca={pca}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <GlobalStyle theme={theme} />
              <Suspense fallback={Splash}>
                <Switch>
                  <Route component={MainRoutes} />
                </Switch>
              </Suspense>
            </ThemeProvider>
          </ConnectedRouter>
        </MsalEventWrapper>
      </Provider>
    </MsalProvider>
  )
}

App.propTypes = {
  pca: PropTypes.shape({
    setNavigationClient: PropTypes.func.isRequired,
  }).isRequired,
}
export default App
