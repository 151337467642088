/* eslint-disable no-case-declarations */
import { findIndex } from 'lodash'
import {
  SET_CONTROLS,
  RESET_CONTROLS,
  UNSET_CONTROLS,
  UPDATE_CONTROLS,
  DISABLE_CONTROLS,
} from './actions'

const initialState = {
  buttons: [],
  prevButtons: [],
  initializing: false,
  initialized: false,
  allowRouting: true,
}

const controlsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTROLS:
      return {
        ...state,
        buttons: [...action.payload.buttons],
        prevButtons: [...state.buttons],
        initializing: false,
        initialized: true,
      }
    case RESET_CONTROLS:
      const objRet = JSON.parse(JSON.stringify(state))
      objRet.buttons = [...state.prevButtons]
      objRet.prevButtons = [...state.buttons]
      return objRet
    case UNSET_CONTROLS:
      return {
        ...state,
        buttons: [],
        prevButtons: [...state.buttons],
        initializing: false,
        initialized: true,
      }
    case DISABLE_CONTROLS:
      if (state.buttons) {
        const btns = []
        state.buttons.map((btn) => btns.push({ ...btn, disabled: true }))
        return {
          ...state,
          buttons: btns,
          prevButtons: [...state.buttons],
        }
      }
      return {
        ...state,
        buttons: [],
        prevButtons: [...state.buttons],
      }
    case UPDATE_CONTROLS:
      const btns = state.buttons
      const idx = findIndex(btns, { char: action.payload.char })
      btns.splice(idx, 1, action.payload)
      return {
        ...state,
        buttons: btns,
        prevButtons: [...state.buttons],
      }
    default:
      return state
  }
}

export default controlsReducer
