import React, { useEffect, useCallback, useState, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik, Field, Form } from 'formik'
import { Flex, Heading, Text, Button } from 'rebass/styled-components'
import { Label } from '@rebass/forms/styled-components'
import { isEmpty } from 'lodash'

import { useTranslation, Trans } from 'react-i18next'

import FormField from '../FormField/FormField'
import Dropdown from './components/Dropdown'
import resource from './messages/en'
import validationSchema from './schemas/validation'

import { setControls } from '../../redux/controls/actions'
import { updateServerConfiguration, getRandomValues } from '../../redux/server/actions'
import { initRandom, getRandom, isFetchingSettings } from '../../redux/server/selectors'
import { getUser } from '../../redux/user/selectors'

import Loading from '../Loading/Loading'

const buttons = [
  {
    char: 'a',
    title: 'select',
    keyMap: 65,
  },
  {
    char: 'y',
    title: 'random',
    action: 'myserver/CONFIG_GET_RANDOM_INPUTS',
    keyMap: 89,
  },
  {
    char: 'x',
    title: 'Advanced settings',
    action: 'myserver/GET_SETTINGS_LINK_REQUEST',
    keyMap: 88,
  },
]

const disabledButtons = [
  {
    char: 'a',
    title: 'select',
    keyMap: 65,
    disabled: true,
  },
  {
    char: 'y',
    title: 'random',
    action: 'myserver/CONFIG_GET_RANDOM_INPUTS',
    keyMap: 89,
    disabled: true,
  },
  {
    char: 'x',
    title: 'Advanced settings',
    action: 'myserver/GET_SETTINGS_LINK_REQUEST',
    keyMap: 88,
    disabled: true,
  },
]

const dropButtons = [
  {
    char: 'a',
    title: 'select',
    keyMap: 65,
  },
  {
    char: 'y',
    title: 'random',
    action: 'myserver/CONFIG_GET_RANDOM_INPUTS',
    keyMap: 89,
    disabled: true,
  },
  {
    char: 'x',
    title: 'Advanced settings',
    action: 'myserver/GET_SETTINGS_LINK_REQUEST',
    keyMap: 88,
    disabled: true,
  },
]

const ConfigurationForm = ({ server, portal }) => {
  const {
    id,
    name,
    online,
    ip_and_port: ipAddress,
    adminPassword,
    password,
    product,
    possible_slots: possibleSlots,
    slot_size: slotSize,
    setting_url: settingUrl,
    settings,
  } = server

  const { name: productName, game_key: gameKey } = product
  const dispatch = useDispatch()

  const [buttonFocused, setButtonFocused] = useState(false)
  const scrollRef = useRef()
  const topRef = useRef()

  const { i18n } = useTranslation()
  i18n.addResources('en', 'configurationform', resource)
  const [options, setOptions] = useState([])
  const [dynamicSettings, setDynamicSettings] = useState([])

  const [nameState, setNameState] = useState(name)
  const [passwordState, setPasswordState] = useState(password)
  const [adminPasswordState, setAdminPasswordState] = useState(adminPassword)

  const setButtons = useCallback(() => dispatch(setControls(buttons)), [dispatch])
  const setDropButtons = useCallback(() => dispatch(setControls(dropButtons)), [dispatch])
  const setDisabledButtons = useCallback(() => dispatch(setControls(disabledButtons)), [dispatch])

  const userSelector = useSelector(getUser)
  const [currentUser, setCurrentUser] = useState(userSelector)
  useEffect(() => {
    setCurrentUser(userSelector)
  }, [userSelector])

  const randomSelector = useSelector(initRandom)
  const randomValues = useCallback(() => dispatch(getRandomValues(currentUser)), [
    dispatch,
    currentUser,
  ])
  useEffect(() => {
    if (randomSelector) randomValues()
  }, [randomSelector, randomValues])

  const randomObjSelector = useSelector(getRandom)
  useEffect(() => {
    if (!isEmpty(randomObjSelector)) {
      setNameState(randomObjSelector.serverName)
      setPasswordState(randomObjSelector.serverPassword)
      setAdminPasswordState(randomObjSelector.adminPassword)
    }
  }, [randomObjSelector])

  const settingsSelector = useSelector(isFetchingSettings)
  useEffect(() => {
    if (settingsSelector) {
      if (typeof Windows !== 'undefined') {
        // eslint-disable-next-line no-undef
        Windows.System.Launcher.launchUriAsync(
          // eslint-disable-next-line no-undef
          new Windows.Foundation.Uri(settingUrl)
        ).then((success) => {
          if (success) {
            dispatch({ type: 'myserver/GET_SETTINGS_LINK_SUCCESS' })
          } else {
            dispatch({ type: 'myserver/GET_SETTINGS_LINK_FAILURE' })
          }
        })
      } else {
        dispatch({ type: 'myserver/GET_SETTINGS_LINK_FAILURE' })
      }
    }
  }, [settingsSelector, settingUrl, dispatch])

  // useEffect(() => {
  //   if (buttonFocused) setButtons()
  //   // return () => resetButtons()
  // }, [setButtons, resetButtons, buttonFocused])

  useEffect(() => {
    // setButtons()
    if (buttonFocused) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    } else if (portal?.current !== 'undefined')
      portal.current.scrollIntoView({ behavior: 'smooth' })
  }, [buttonFocused, portal, setButtons])

  useEffect(() => {
    if (possibleSlots.length > 0) {
      const slotArray = []
      possibleSlots.map((slot) => slotArray.push({ label: slot, value: slot }))
      setOptions(slotArray)
    }
  }, [possibleSlots])

  useEffect(() => {
    if (settings.length > 0) {
      const dynamics = []
      settings.map((dyn) => {
        dynamics.push({ name: dyn.name, options: dyn.options, value: dyn.value })
        return true
      })
      setDynamicSettings(dynamics)
    }
  }, [settings])

  const showServerNameField = true
  const showServerPasswordField = true
  const showAdminPasswordField = gameKey !== 'VAL_XBOX' && gameKey !== 'SMALLAND_XBOX'
  const showSlotsField = gameKey !== 'VAL_XBOX' && gameKey !== 'SMALLAND_XBOX'

    const initialValues = {
        serverId: id,
        serverName: nameState,
        serverPassword: passwordState,
    }
  if (showAdminPasswordField) {
      initialValues[adminPassword] = adminPasswordState
  }
  if (showSlotsField) {
      initialValues.slotSize = slotSize
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        px: '100px',
        py: '40px',
      }}
      ref={topRef}
    >
      <Flex flexDirection="column">
        <Text
          sx={{
            color: online ? 'success' : 'danger',
            textTransform: 'uppercase',
          }}
        >
          {online ? 'online' : 'offline'}
        </Text>
        <Heading variant="headline">{name}</Heading>
        <Heading variant="headlineLight">{productName}</Heading>
        <Text
          sx={{
            color: 'text',
            fontSize: '18px',
          }}
        >
          {`${ipAddress}`}
        </Text>
      </Flex>

      {!randomSelector ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {

            setSubmitting(false)
            if (values.save) dispatch(updateServerConfiguration(values, currentUser))
            dispatch({ type: 'HIDE_PORTAL' })
          }}
          validationSchema={validationSchema}
        >
          {({ values, handleSubmit, isSubmitting, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {showServerNameField && (
                <Label pt="50px" htmlFor="serverName">
                  <Trans i18nKey="configurationform:serverName" />
                </Label>
                )}
                {showServerNameField && (
                <Field type="text" name="serverName">
                  {({ form, field, meta }) => {
                    return (
                      <FormField
                        form={form}
                        field={field}
                        meta={meta}
                        buttonControl={() => {
                          setDisabledButtons()
                          setButtonFocused(false)
                        }}
                        tabIndex="0"
                      />
                    )
                  }}
                </Field>
                )}
                {showServerPasswordField && (
                <Label htmlFor="serverPassword">
                  <Trans i18nKey="configurationform:serverPassword" />
                </Label>
                )}
                {showServerPasswordField && (
                <Field type="text" name="serverPassword">
                  {({ form, field, meta }) => (
                    <FormField
                      form={form}
                      field={field}
                      meta={meta}
                      buttonControl={() => {
                        setDisabledButtons()
                        setButtonFocused(false)
                      }}
                      tabIndex="0"
                    />
                  )}
                </Field>
                )}
                {showAdminPasswordField && (
                <Label htmlFor="adminPassword">
                  <Trans i18nKey="configurationform:adminPassword" />
                </Label>
                )}
                {showAdminPasswordField && (
                <Field type="text" name="adminPassword">
                  {({ form, field, meta }) => (
                    <FormField
                      form={form}
                      field={field}
                      meta={meta}
                      buttonControl={() => {
                        setDisabledButtons()
                        setButtonFocused(false)
                      }}
                      tabIndex="0"
                    />
                  )}
                </Field>
                )}
                {showSlotsField && (
                    <Label htmlFor="slotSize">
                      <Trans i18nKey="configurationform:slots" />
                    </Label>
                )}
                    {showSlotsField && options.length > 0 && (
                      <Dropdown
                        name="slotSize"
                        options={options}
                        setFieldValue={setFieldValue}
                        value={values.slotSize}
                        tabIndex="0"
                        buttonControl={() => {
                          setDropButtons()
                          setButtonFocused(false)
                        }}
                      />
                    )}

                {dynamicSettings.length > 0 &&
                  dynamicSettings.map((dynamic) => (
                    <Fragment key={dynamic.name}>
                      <Label htmlFor="ap">
                        <Trans i18nKey="configurationform:maps" />
                      </Label>
                      <Dropdown
                        name={`settings[${dynamic.name}]`}
                        options={dynamic.options}
                        setFieldValue={setFieldValue}
                        value={dynamic.value}
                        tabIndex="0"
                        buttonControl={() => {
                          setDropButtons()
                          setButtonFocused(false)
                        }}
                        // setDisplayContent={setDisplayContent}
                        // defaultDisplayContent={defaultDisplayContent}
                        // Component={Modification}
                      />
                    </Fragment>
                  ))}

                <Button
                  width={[1]}
                  variant="submit"
                  type="button"
                  disabled={isSubmitting}
                  onFocus={() => {
                    setButtons()
                    setButtonFocused(true)
                  }}
                  onClick={() => {
                    setFieldValue('save', true, false)
                    handleSubmit()
                  }}
                  tabIndex="0"
                >
                  <Trans i18nKey="configurationform:submit" />
                </Button>
                <Button
                  width={[1]}
                  variant="submit"
                  type="button"
                  disabled={isSubmitting}
                  mt="2em"
                  onFocus={() => {
                    setButtons()
                    setButtonFocused(true)
                  }}
                  onClick={() => {
                    setFieldValue('save', false, false)
                    handleSubmit()
                  }}
                  tabIndex="0"
                  ref={scrollRef}
                >
                  <Trans i18nKey="configurationform:cancel" />
                </Button>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <Loading />
        </Flex>
      )}
    </Flex>
  )
}

ConfigurationForm.propTypes = {
  server: PropTypes.shape({
    id: PropTypes.number,
    ip_and_port: PropTypes.string,
    name: PropTypes.string,
    online: PropTypes.bool,
    password: PropTypes.string,
    adminPassword: PropTypes.string,
    possible_slots: PropTypes.arrayOf(PropTypes.number),
    product: PropTypes.shape({
      name: PropTypes.string,
      default_name: PropTypes.string,
      images: PropTypes.shape({
        logo: PropTypes.string,
        cover: PropTypes.string,
      }),
    }),
    slot_size: PropTypes.number,
    setting_url: PropTypes.string,
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  portal: PropTypes.shape({
    current: PropTypes.string,
  }).isRequired,
}

export default ConfigurationForm
