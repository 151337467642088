import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'rebass/styled-components'

import Button from '../styled/Button'
import Title from '../styled/Title'

const FullfillmentButton = ({ button, color, disabled, isPressed, isReleased, cb }) => {
  const [pressed, setPressed] = useState(isPressed)
  const [fullfilled, setFullfilled] = useState(false)
  const [released, setReleased] = useState(true)

  const handleFullfilled = useCallback(() => {
    if (!fullfilled) {
      setFullfilled(true)
      cb()
    }
  }, [cb, setFullfilled, fullfilled])

  useEffect(() => {
    if (pressed) {
      handleFullfilled()
    }
  }, [pressed, handleFullfilled])

  useEffect(() => {
    setPressed(isPressed)
  }, [isPressed])

  useEffect(() => {
    setReleased(isReleased)
  }, [isReleased])

  useEffect(() => {
    if (released) setFullfilled(false)
  }, [released])

  return (
    <Flex flexGrow={1}>
      <Button
        sx={{
          width: '36px',
          height: '36px',
        }}
        disabled={disabled}
        isPressed={isPressed}
        color={color}
        // color={isPressed ? color.backgroundColor : color.color}
        // bg={isPressed ? color.color : color.backgroundColor}
      >
        {button.char.toUpperCase()}
      </Button>
      <Flex alignItems="center">
        <Title lowerOpacity={disabled} color="lightGrey">
          {button.title.toUpperCase()}
        </Title>
      </Flex>
    </Flex>
  )
}

FullfillmentButton.propTypes = {
  button: PropTypes.shape({
    action: PropTypes.string,
    char: PropTypes.string,
    keyMap: PropTypes.number,
    timed: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  color: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  isPressed: PropTypes.bool,
  isReleased: PropTypes.bool,
  cb: PropTypes.func,
}

FullfillmentButton.defaultProps = {
  disabled: false,
  isPressed: false,
  isReleased: false,
  cb: () => true,
}

export default FullfillmentButton
