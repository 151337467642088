import {
  START_AUTH,
  USER_IS_LIVE,
  USER_NOT_LIVE,
  USER_IS_MIGRATED,
  USER_IS_AUTHENTICATED,
  USER_FAILED_AUTHENTICATION,
  USER_BUTTON_STATE,
  ACCOUNT_BUTTON_STATE,
  GATE_SELECT_CURRENT,
  PUSH_TO_PORTAL,
  TOGGLE_LOADING,
  USER_SHOULD_LOGOUT,
  USER_TOKEN_SUCCESS,
  START_PURCHASE_PROCESS,
  USER_B2B_SUCCESS,
  GET_WEBINTERFACE_LINK_SUCCESS,
  USER_GET_SUPPORT,
  USER_SHOULD_MANAGE_SUBSCRIPTIONS
} from './actions'

const initialState = {
  account: null,
  accountButtonState: null,
  accountIdentifier: null,
  authStarted: false,
  authToken: null,
  b2b: null,
  purchaseToken: null,
  buttonState: null,
  email: null,
  hasSelection: null,
  isAuthenticated: false,
  isLive: false,
  isMigrated: false,
  pushToPortal: null,
  shouldLogout: null,
  shouldManageSubscriptions: null,
  shouldMigrate: null,
  shouldNavigate: null,
  state: null,
  tokenProcess: false,
  supportUri: null,
}

const userReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case START_AUTH:
      return {
        ...state,
        authStarted: true,
        pushToPortal: false,
      }
    case USER_IS_LIVE:
      return {
        ...state,
        isLive: true,
        account: payload,
      }
    case USER_NOT_LIVE:
      return {
        ...state,
        isLive: false,
        account: null,
      }
    case USER_IS_MIGRATED:
      return {
        ...state,
        isMigrated: true,
      }
    case USER_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        isMigrated: true,
        isLive: true,
        authStarted: false,
        accountIdentifier: payload.payload.account_identifier,
        authToken: payload.payload.auth_token,
        email: payload.payload.email,
        b2b: payload.payload.b2b_key_expire,
      }
    case USER_FAILED_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: false,
        authStarted: false,
      }
    case USER_BUTTON_STATE:
      return {
        ...state,
        buttonState: payload,
        shouldNavigate: false,
      }
    case USER_GET_SUPPORT:
      return {
        ...state,
        accountButtonState: 'support',
        shouldNavigate: true,
      }
    case ACCOUNT_BUTTON_STATE:
      return {
        ...state,
        accountButtonState: payload,
        shouldNavigate: false,
      }
    case GATE_SELECT_CURRENT:
      return {
        ...state,
        shouldNavigate: true,
      }
    case PUSH_TO_PORTAL:
      return {
        ...state,
        pushToPortal: true,
        shouldNavigate: false,
        buttonState: null,
      }
    case TOGGLE_LOADING:
      return {
        ...state,
        authStarted: !state.authStarted,
      }
    case USER_SHOULD_LOGOUT:
      return {
        ...state,
        shouldLogout: true,
      }
    case USER_SHOULD_MANAGE_SUBSCRIPTIONS:
      return {
        ...state,
        shouldManageSubscriptions: true,
      }
    case START_PURCHASE_PROCESS:
      return {
        ...state,
        tokenProcess: true,
      }
    case USER_TOKEN_SUCCESS:
      return {
        ...state,
        purchaseToken: payload,
      }
    case USER_B2B_SUCCESS:
      return {
        ...state,
        b2b: payload,
        tokenProcess: false,
      }
    case GET_WEBINTERFACE_LINK_SUCCESS:
      return {
        ...state,
        supportUri: payload,
      }

    default:
      return state
  }
}

export default userReducer
