/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-undef */
import axios from 'axios'

export function simpleAuthUser(username, localAccountId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_WEB_API_URL}auth`, {
        email: username,
        account_identifier: localAccountId,
      })
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

export function newAuthUser(username, localAccountId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_WEB_API_URL}auth`, {
        email: username,
        account_identifier: localAccountId,
        new_account: 1,
      })
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

export function migrateUser(user, formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_WEB_API_URL}auth`, {
        email: user.username,
        account_identifier: user.localAccountId,
        password: formData.userPassword,
        old_email: formData.userName,
      })
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

export function getPurchaseToken(user) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}tokens/purchase`, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function postB2BKey(token, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_WEB_API_URL}customer/b2bKey`,
        { key: token },
        {
          headers: {
            email: user.email,
            hash: user.authToken,
          },
        }
      )
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function fetchSupportLink(user) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}support`, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
