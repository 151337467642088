import React, { useState, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import { Flex } from 'rebass/styled-components'

import Button from '../styled/Button'

import TimedButton from './TimedButton'
import FullfillmentButton from './FullfillmentButton'

const ButtonControl = ({ button, disabled, isPressed, isReleased, callBack }) => {
  const [colorObj, setColorObj] = useState({})

  const themeContext = useContext(ThemeContext)
  const getBackgroundFromButtonChar = useCallback(() => {
    const newColorObj = {}
    const { char } = button
    if (char.toLowerCase() === 'a') {
      newColorObj.color = themeContext.colors.buttonGreen
      newColorObj.backgroundColor = themeContext.colors.secondary
    } else if (char.toLowerCase() === 'b') {
      newColorObj.color = themeContext.colors.buttonRed
      newColorObj.backgroundColor = themeContext.colors.secondary
    } else if (char.toLowerCase() === 'x') {
      newColorObj.color = themeContext.colors.buttonBlue
      newColorObj.backgroundColor = themeContext.colors.secondary
    } else if (char.toLowerCase() === 'y') {
      newColorObj.color = themeContext.colors.buttonYellow
      newColorObj.backgroundColor = themeContext.colors.secondary
    } else {
      newColorObj.color = 'white'
    }
    setColorObj(newColorObj)
  }, [button, themeContext])

  useEffect(() => {
    getBackgroundFromButtonChar()
  }, [getBackgroundFromButtonChar])

  if (button.timed) {
    return (
      <TimedButton
        button={button}
        color={colorObj}
        disabled={disabled}
        isPressed={isPressed}
        isReleased={isReleased}
        cb={callBack}
      />
    )
  }

  if (button.title) {
    return (
      <FullfillmentButton
        button={button}
        color={colorObj}
        disabled={disabled}
        isPressed={isPressed}
        isReleased={isReleased}
        cb={callBack}
      />
    )
  }
  return (
    <Flex>
      <Button
        py="4px"
        px="12px"
        fontSize="14px"
        borderRadius="5px"
        borderColor="white"
        lineHeight="0.5"
        basis="40px"
        lowerOpacity={disabled}
        color={colorObj.color}
        bg={colorObj.backgroundColor}
      >
        {button.char.toUpperCase()}
      </Button>
    </Flex>
  )
}
ButtonControl.propTypes = {
  button: PropTypes.shape({
    char: PropTypes.string.isRequired,
    title: PropTypes.string,
    action: PropTypes.string,
    timed: PropTypes.number,
    keyMap: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool,
  isPressed: PropTypes.bool,
  isReleased: PropTypes.bool,
  callBack: PropTypes.func,
}

ButtonControl.defaultProps = {
  disabled: false,
  callBack: () => true,
  isPressed: false,
  isReleased: false,
}

export default ButtonControl
