// styled-components based extension
import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Menu = styled(Flex)(
  () => css`
    padding: 0 100px;
    position: relative;
    &:after {
      content: '';
      background: linear-gradient(to bottom, #1e262d 0%, #1e262d 15%, transparent 100%);
      position: absolute;
      left: 0;
      bottom: -50px;
      width: 100%;
      height: 50px;
      z-index: 1000;
    }
  `
)

Menu.defaultProps = {
  m: 0,
  p: '2em',
}

const MenuItem = styled(Flex)(
  (props) => css`
    margin: 0;
    padding: 0;
    padding-right: 40px;
    color: ${props.theme.colors.text};
    font-weight: 900;
    font-size: 36px;
    position: relative;
    text-decoration: none;
    opacity: 0.6;

    ${props.selected &&
    css`
      opacity: 1;
      &:after {
        content: '';
        border-bottom: 2px solid;
        border-color: ${props.theme.colors.lightBlue};
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 40px;
        height: 2px;
        z-index: 1001;
      }
    `}
  `
)

Menu.MenuItem = MenuItem

export default Menu
