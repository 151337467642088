import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import * as Sentry from "@sentry/react";

import { Flex, Box, Button, Heading, Image, Text } from 'rebass/styled-components'
import { Redirect } from 'react-router-dom'

import { setControls, unsetControls } from '../../redux/controls/actions'

import { Login as LoginLayout } from '../../layouts'
import gportalLogo from '../../assets/_logo.svg'
import xboxLogo from '../../assets/icons/xbox_logo.svg'

import { ImageColumn, ColumnTransform, Logo } from '../../components'

import resource from './messages/en'
import buttons from './controls'
import { messengerAction } from '../../redux/messages/actions';

const Login = () => {
  const { t, i18n } = useTranslation()
  i18n.addResources('en', 'login', resource)

  const dispatch = useDispatch()
  const { instance } = useMsal()

  const setButtons = useCallback(() => dispatch(setControls(buttons)), [dispatch])
  const resetButtons = useCallback(() => dispatch(unsetControls()), [dispatch])

  const colRef = useRef()
  const [transformHeight, setTransformHeight] = useState(0)

  useEffect(() => {
    setButtons()
    return () => resetButtons()
  }, [setButtons, resetButtons])

  useEffect(() => {
    setTransformHeight(colRef.current.getBoundingClientRect().height)
  }, [colRef])

  const [ isLogginIn, setIsLogginIn ] = useState();
  const handleLogin = async () => {
    if (isLogginIn) return;

    setIsLogginIn(true);
    try {
      await instance.handleRedirectPromise();
      await instance.loginRedirect(); 
    } catch (error) {
      Sentry.captureException(error);
      dispatch(messengerAction({
        level: 'error',
        message: "login_error"
      }, 'login'));
    } finally {
      setIsLogginIn(false);
    }
  }

  return (
    <LoginLayout>
      <Flex
        // width={[2 / 5]}
        // sx={{
        //   flexDirection: 'column',
        //   justifyContent: 'center',
        //   padding: '40px 0 40px 100px',
        // }}
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '40px 0 40px 100px',
          width: '33%',
          minWidth: '640px',
          flexShrink: 0,
        }}
      >
        <Box>
          <Logo
            src={gportalLogo}
            sx={{
              pb: 2,
            }}
          />
        </Box>
        <Box>
          <UnauthenticatedTemplate>
            <Heading as="h3" variant="headline" py="3" width={2 / 3}>
              {t(`login:headline`)}
            </Heading>
            <Button variant="xboxlive" width={[1 / 2]} onClick={handleLogin} disabled={isLogginIn}>
              <Image src={xboxLogo} flexShrink={1} height={40} width={40} mr={20} />
              {t(`login:btnText`)}
            </Button>
            {/* </Text> */}
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Redirect to="portal" />
          </AuthenticatedTemplate>
        </Box>

        <Box
          sx={{
            p: 4,
            position: 'absolute',
            bottom: 40,
            zIndex: 1001,
            maxWidth: "500px",
            borderRadius: 4,
            backgroundColor: "#ff9525"
          }}
        >
          <Heading as="h4" variant="subheadline" mb="3" width={2 / 3}>
            {t(`login:deprecated_headline`)}
          </Heading>

          <Text>
            {t(`login:deprecated_text`)}
          </Text>
        </Box>
      </Flex>

      {/* <Flex flexGrow={1} overflow="hidden" mr="-40px">
        <AnimatedColumn width={[1 / 6]} p="20px" />
      </Flex> */}
      <ColumnTransform
        ref={colRef}
        flexGrow={1}
        flexShrink={0}
        overflow="hidden"
        mr="-40px"
        th={transformHeight}
      >
        <ImageColumn width={[1 / 6]} p="20px" />
        <ImageColumn width={[1 / 6]} p="20px" />
        <ImageColumn width={[1 / 6]} p="20px" />
        <ImageColumn width={[1 / 6]} p="20px" />
        <ImageColumn width={[1 / 6]} p="20px" />
        <ImageColumn width={[1 / 6]} p="20px" />
      </ColumnTransform>
    </LoginLayout>
  )
}

export default Login
