/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from "@sentry/react";

import PropTypes from 'prop-types'
import { Flex } from 'rebass/styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../../config/authConfig'

import { initUserAuth, initLogout, initB2BHandling } from '../../redux/user/actions'
import {
  getUserAuthState,
  getMsalUser,
  getUser,
  shouldLogout,
  getTokenProcess,
} from '../../redux/user/selectors'

import { setProducts } from '../../redux/cart/actions'
import { getProductListings } from '../../redux/cart/selectors'

import { getPortalVisibility } from '../../redux/ui/selectors'

import Container from './styled/Container'
import Logo from './styled/Logo'

import gportalLogo from '../../assets/logo.svg'
import Loading from '../../components/Loading/Loading'

import { MainMenu, ControlState } from '../../components'

const Layout = ({ children }) => {
  const authRequest = {
    ...loginRequest,
  }
  const dispatch = useDispatch()

  const portalSelector = useSelector(getPortalVisibility)
  const [hasPortal, setHasPortal] = useState(portalSelector)
  useEffect(() => {
    setHasPortal(portalSelector)
  }, [portalSelector])

  const productsSelector = useSelector(getProductListings)
  const productsCallback = useCallback((products) => dispatch(setProducts(products)), [dispatch])

  useEffect(() => {
    if (!productsSelector) {
      if (typeof Windows !== 'undefined') {
        const store = Windows.Services.Store.StoreContext.getDefault()
        const productsK = ['Durable']
        store.getAssociatedStoreProductsAsync(productsK).then((data) => {
          if (data?.products) productsCallback(data.products)
        })
      }
    }
  }, [productsSelector, productsCallback])

  // User auth try-out on page render ...
  const msalUserSelector = useSelector(getMsalUser)
  const authSelector = useSelector(getUserAuthState)
  const authUser = useCallback(() => {
    Sentry.setUser(
      {
        email: msalUserSelector.username,
        msalUserSelector
      }
    );
    dispatch(initUserAuth(msalUserSelector.username, msalUserSelector.localAccountId))
  },
  [msalUserSelector, dispatch]
  )

  useEffect(() => {
    if (msalUserSelector && !authSelector) authUser()
  }, [msalUserSelector, authSelector, authUser])

  const authStateSelector = useSelector(getUserAuthState)
  const [authState, setAuthState] = useState(authStateSelector)
  useEffect(() => {
    setAuthState(authSelector)
  }, [authSelector])

  const logoutSelector = useSelector(shouldLogout)
  const doLogout = useCallback(() => dispatch(initLogout()), [dispatch])
  useEffect(() => {
    if (logoutSelector) {
      Sentry.setUser(null);
      doLogout()
    }
  }, [logoutSelector, doLogout])

  const userSelector = useSelector(getUser)
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(userSelector)
  const tokenProcessSelector = useSelector(getTokenProcess)
  const b2bTokenHandling = useCallback(() => dispatch(initB2BHandling(userSelector)), [
    dispatch,
    userSelector,
  ])
  useEffect(() => {
    setUser(userSelector)
    if (authSelector && userSelector && !userSelector?.b2b && !tokenProcessSelector) {
      b2bTokenHandling()
    }
  }, [userSelector, authSelector, tokenProcessSelector, b2bTokenHandling])

  return (
    <Container flexDirection="column">
      <Flex flexGrow={0} px="100px" justifyContent="space-between">
        <Logo src={gportalLogo} />
      </Flex>
      <MainMenu />
      <Flex
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          padding: '30px 0 100px 0',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={Loading}
          loadingComponent={Loading}
        >
          {authState ? (
            children
          ) : (
            <Flex alignSelf="center" flexGrow="1" alignItems="center">
              <Loading />
            </Flex>
          )}
        </MsalAuthenticationTemplate>
      </Flex>
      <Flex
        sx={{
          position: 'absolute',
          left: '0px',
          bottom: '0px',
          height: '125px',
          width: '100%',
          zIndex: '1099',
          backgroundImage: hasPortal
            ? 'linear-gradient(to bottom, transparent, transparent 75%)'
            : 'linear-gradient(to bottom, transparent, #1e262d 75%)',
        }}
      />
      <Flex
        sx={{
          position: 'absolute',
          left: '100px',
          bottom: '25px',
          zIndex: '1100',
        }}
      >
        <ControlState />
      </Flex>
      <ToastContainer />
    </Container>
  )
}

Layout.defaultProps = {
  children: {},
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
