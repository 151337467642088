import { push } from 'connected-react-router'
import {
  fetchMyServer,
  postUpdateServerConfiguration,
  stopMyServer,
  startMyServer,
  restartMyServer,
  fetchRandomsForConfiguration,
} from './repository'

import { messengerAction } from '../messages/actions'

export const GET_MYSERVER_REQUEST = 'myserver/GET_REQUEST'
export const GET_MYSERVER_SUCCESS = 'myserver/GET_SUCCESS'
export const GET_MYSERVER_FAILURE = 'myserver/GET_FAILURE'

export const POST_RESTART_SERVER_REQUEST = 'myserver/POST_RESTART_SERVER_REQUEST'
export const POST_RESTART_SERVER_SUCCESS = 'myserver/POST_RESTART_SERVER_SUCCESS'
export const POST_RESTART_SERVER_FAILURE = 'myserver/POST_RESTART_SERVER_FAILURE'

export const POST_STOP_SERVER_REQUEST = 'myserver/POST_STOP_SERVER_REQUEST'
export const POST_STOP_SERVER_SUCCESS = 'myserver/POST_STOP_SERVER_SUCCESS'
export const POST_STOP_SERVER_FAILURE = 'myserver/POST_STOP_SERVER_FAILURE'

export const POST_START_SERVER_REQUEST = 'myserver/POST_START_SERVER_REQUEST'
export const POST_START_SERVER_SUCCESS = 'myserver/POST_START_SERVER_SUCCESS'
export const POST_START_SERVER_FAILURE = 'myserver/POST_START_SERVER_FAILURE'

export const GET_WEBINTERFACE_LINK_REQUEST = 'myserver/GET_WEBINTERFACE_LINK_REQUEST'
export const GET_WEBINTERFACE_LINK_SUCCESS = 'myserver/GET_WEBINTERFACE_LINK_SUCCESS'
export const GET_WEBINTERFACE_LINK_FAILURE = 'myserver/GET_WEBINTERFACE_LINK_FAILURE'

export const POST_UPDATE_SERVER_CONFIGURATION_REQUEST =
  'myserver/POST_UPDATE_SERVER_CONFIGURATION_REQUEST'
export const POST_UPDATE_SERVER_CONFIGURATION_SUCCESS =
  'myserver/POST_UPDATE_SERVER_CONFIGURATION_SUCCESS'
export const POST_UPDATE_SERVER_CONFIGURATION_FAILURE =
  'myserver/POST_UPDATE_SERVER_CONFIGURATION_FAILURE'

export const CLEAR_WEBINTERFACE_LINK = 'myserver/CLEAR_WEBINTERFACE_LINK'

export const SET_CURRENT_SERVER_SUCCESS = 'myserver/SET_CURRENT_SERVER'

export const RESTART_SERVER = 'myserver/RESTART_SERVER'
export const STOP_SERVER = 'myserver/STOP_SERVER'
export const START_SERVER = 'myserver/START_SERVER'

export const UPDATE_SERVER_ONLINE_STATE = 'myserver/UPDATE_SERVER_ONLINE_STATE'

export const CONFIG_GET_RANDOM_INPUTS = 'myserver/CONFIG_GET_RANDOM_INPUTS'
export const CONFIG_SUCCESS_RANDOM_INPUTS = 'myserver/CONFIG_SUCCESS_RANDOM_INPUTS'
export const CONFIG_FAILED_RANDOM_INPUTS = 'myserver/CONFIG_FAILED_RANDOM_INPUTS'

export const GET_SETTINGS_LINK_REQUEST = 'myserver/GET_SETTINGS_LINK_REQUEST'
export const GET_SETTINGS_LINK_SUCCESS = 'myserver/GET_SETTINGS_LINK_SUCCESS'
export const GET_SETTINGS_LINK_FAILURE = 'myserver/GET_SETTINGS_LINK_FAILURE'

export function startGetMyServer() {
  return {
    type: GET_MYSERVER_REQUEST,
  }
}

export function successGetMyServer(server) {
  return {
    type: GET_MYSERVER_SUCCESS,
    payload: server,
  }
}

export function selectCurrentServer(server) {
  return {
    type: SET_CURRENT_SERVER_SUCCESS,
    payload: server,
  }
}

export function startServerStart() {
  return {
    type: POST_START_SERVER_REQUEST,
  }
}

export function startServerSuccessfully() {
  return {
    type: POST_START_SERVER_SUCCESS,
  }
}

export function startServerFailure(error) {
  return {
    type: POST_START_SERVER_FAILURE,
    payload: error,
  }
}

// Start the restart process
export function restartServerStart() {
  return {
    type: POST_RESTART_SERVER_REQUEST,
  }
}

export function failureGetMyServer(error) {
  return {
    type: GET_MYSERVER_FAILURE,
    payload: error,
  }
}

export function getMyServer(user, silent) {
  return (dispatch) => {
    if (!silent) {
      dispatch(startGetMyServer())
    }
    fetchMyServer(user)
      .then((response) => {
        dispatch(successGetMyServer(response))
        if (response?.data?.message) dispatch(messengerAction(response.data.message, 'getMyServer'))
        if (!response.data.payload.length) dispatch(push('/rent'))
      })
      .catch((error) => {
        dispatch(failureGetMyServer(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'getMyServer'))
      })
  }
}

export function startServer(server, user) {
  return (dispatch) => {
    dispatch(startServerStart())
    startMyServer(server, user)
      .then((response) => {
        // dispatch(getMyServer(user, true))
        dispatch(startServerSuccessfully())
        if (response?.data?.message) dispatch(messengerAction(response.data.message, 'startServer'))
      })
      .catch((error) => {
        dispatch(startServerFailure(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'startServer'))
      })
  }
}

// Restart was successfully
export function restartServerSuccess() {
  return {
    type: POST_RESTART_SERVER_SUCCESS,
  }
}

// Restart faild
export function restartServerFailure(error) {
  return {
    type: POST_RESTART_SERVER_FAILURE,
    payload: error,
  }
}

// POST the restart request to the API
export function restartServer(server, user) {
  return (dispatch) => {
    dispatch(restartServerStart())
    restartMyServer(server, user)
      .then((response) => {
        // dispatch(getMyServer(user, true))
        dispatch(restartServerSuccess())
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'restartServer'))
      })
      .catch((error) => {
        dispatch(restartServerFailure(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'restartServer'))
      })
  }
}

// Start the stop process
export function stopServerStart() {
  return {
    type: POST_STOP_SERVER_REQUEST,
  }
}

// stop was successfully
export function stopServerSuccess() {
  return {
    type: POST_STOP_SERVER_SUCCESS,
  }
}

// stop failed
export function stopServerFailure(error) {
  return {
    type: POST_STOP_SERVER_FAILURE,
    payload: error,
  }
}

// POST the stop request to the API
export function stopServer(server, user) {
  return (dispatch) => {
    dispatch(stopServerStart())
    stopMyServer(server, user)
      .then((response) => {
        dispatch(stopServerSuccess())
        // dispatch(getMyServer(user, false))
        if (response?.data?.message) dispatch(messengerAction(response.data.message, 'stopServer'))
      })
      .catch((error) => {
        dispatch(stopServerFailure(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'stopServer'))
      })
  }
}

export function updateServerConfigurationRequest() {
  return {
    type: POST_UPDATE_SERVER_CONFIGURATION_REQUEST,
  }
}

export function updateServerConfigurationSuccess() {
  return {
    type: POST_UPDATE_SERVER_CONFIGURATION_SUCCESS,
  }
}

export function updateServerConfigurationFailure(error) {
  return {
    type: POST_UPDATE_SERVER_CONFIGURATION_SUCCESS,
    payload: error,
  }
}

export function updateServerConfiguration(config, user) {
  return (dispatch) => {
    dispatch(updateServerConfigurationRequest())
    postUpdateServerConfiguration(config, user)
      .then((response) => {
        dispatch(updateServerConfigurationSuccess())
        dispatch(getMyServer(user, false))
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'updateServerConfiguration'))
      })
      .catch((error) => {
        dispatch(updateServerConfigurationFailure(error))
        if (error?.data?.message)
          dispatch(messengerAction(error.data.message, 'updateServerConfiguration'))
        return false
      })
  }
}

export function updateServerOnlineState(stateObj) {
  return {
    type: UPDATE_SERVER_ONLINE_STATE,
    payload: stateObj,
  }
}

export function configRandomSuccess(randomObj) {
  return {
    type: CONFIG_SUCCESS_RANDOM_INPUTS,
    payload: randomObj,
  }
}

export function configRandomFailed() {
  return {
    type: CONFIG_FAILED_RANDOM_INPUTS,
  }
}

export function getRandomValues(user) {
  return (dispatch) => {
    fetchRandomsForConfiguration(user)
      .then((response) => {
        dispatch(configRandomSuccess(response))
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'getRandomValues'))
      })
      .catch((error) => {
        dispatch(configRandomFailed(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'getRandomValues'))
      })
  }
}
