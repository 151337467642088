import React, { useEffect, useState, useCallback } from 'react'
import { findIndex, has, throttle } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Flex } from 'rebass/styled-components'

import Gamepad from '../../libs/gamepad/gamepad'
import { getButtons } from '../../redux/controls/selectors'
import ButtonControl from './components/ButtonControl'

const ControlState = () => {
  const dispatch = useDispatch()
  const buttonSelector = useSelector((state) => getButtons(state))
  const [down, setDown] = useState(0)
  const [up, setUp] = useState(0)

  const handleButtonPress = useCallback(
    ({ keyCode }) => {
      const matchKey = findIndex(buttonSelector, (o) => keyCode === o.keyMap)
      if (matchKey !== -1) {
        if (has(buttonSelector[matchKey], 'action')) {
          if (down !== keyCode) {
            setDown(keyCode)
            setUp(0)
          }
        }
      }
    },
    [buttonSelector, down]
  )

  const handleButtonRelease = useCallback(
    ({ keyCode }) => {
      const matchKey = findIndex(buttonSelector, (o) => keyCode === o.keyMap)
      if (matchKey !== -1) {
        if (has(buttonSelector[matchKey], 'action')) {
          // if (has(buttonSelector[matchKey], 'timed')) {
          setDown(0)
          setUp(keyCode)
          // }
        }
      }
    },
    [buttonSelector]
  )

  const handleGamepadButtonPress = useCallback(
    (btn) => {
      const matchKey = findIndex(buttonSelector, (o) => btn === o.char.toUpperCase())
      if (matchKey !== -1) {
        if (has(buttonSelector[matchKey], 'action')) {
          if (down !== buttonSelector[matchKey].keyMap) {
            setDown(buttonSelector[matchKey].keyMap)
            setUp(0)
          }
        }
      }
    },
    [buttonSelector, down]
  )

  const handleGamepadButtonRelease = useCallback(
    (btn) => {
      const matchKey = findIndex(buttonSelector, (o) => btn === o.char.toUpperCase())
      if (matchKey !== -1) {
        if (has(buttonSelector[matchKey], 'action')) {
          // if (has(buttonSelector[matchKey], 'timed')) {
          setDown(0)
          setUp(buttonSelector[matchKey].keyMap)
          // }
        }
      }
    },
    [buttonSelector]
  )

  const handleFullfillment = useCallback(
    throttle(
      (button) => {
        if (has(button, 'action') && !button.disabled) {
          dispatch({ type: `${button.action}` })
        }
      },
      3000,
      { trailing: false }
    ),
    []
  )

  useEffect(() => {
    window.addEventListener('keydown', handleButtonPress)
    window.addEventListener('keyup', handleButtonRelease)
    return () => {
      window.removeEventListener('keydown', handleButtonPress)
      window.removeEventListener('keyup', handleButtonRelease)
    }
  }, [handleButtonRelease, handleButtonPress])

  return (
    <Flex>
      {buttonSelector &&
        buttonSelector.map((button) => (
          // Avoiding uniqueId as key to avoid uneccessary re-renderings
          <ButtonControl
            button={button}
            key={`mainNav-${button.keyMap}`}
            isPressed={down === button.keyMap}
            isReleased={up === button.keyMap}
            callBack={() => handleFullfillment(button)}
            disabled={button.disabled}
          />
        ))}

      {/* <div style={{ color: 'white' }}>{gamepadDisplay}</div> */}
      <Gamepad
        onButtonDown={(btn) => handleGamepadButtonPress(btn)}
        onButtonUp={(btn) => handleGamepadButtonRelease(btn)}
      >
        <></>
      </Gamepad>
    </Flex>
  )
}

export default ControlState
