import React, { useRef } from 'react'
import { useSpring, animated } from 'react-spring'
// import { Transition, animated } from 'react-spring/renderprops'
import { uniqueId } from 'lodash'
import { Box, Image } from 'rebass/styled-components'

const images = [
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
  {
    id: uniqueId(),
    src: 'https://picsum.photos/200/300',
  },
]

const AnimatedColumn = (props) => {
  const columnRef = useRef(null)

  const animation = useSpring({
    to: async (next) => {
      await next({ opacity: 1, transform: 'translateY(-220px)' })
      await next({ opacity: 0, transform: 'translateY(100px)' })
    },
    from: { opacity: 0, transform: 'translateY(0px)' },
    config: {
      mass: 1,
      tension: 280,
      friction: 160,
    },
  })

  return (
    <Box
      ref={columnRef}
      /* eslint react/jsx-props-no-spreading: 0 */
      {...props}
      // sx={{
      //   overflow: 'scroll',
      // }}
    >
      <animated.div style={animation}>
        {/* {container && 
          renderContainer()
        } */}
        <Image src={images[0].src} />
        <Image src={images[1].src} />
      </animated.div>
    </Box>
  )

  // const animation = useSpring({
  //   from: {
  //     transform: 'translateY(-220px)',
  //   },
  //   to: {
  //     transform: 'translateY(766px)',
  //   },
  //   reset: true,
  //   reverse: true,
  //   config: {
  //     mass: 1,
  //     tension: 280,
  //     friction: 60,
  //   }
  // })
  // console.log(animation)

  // return (
  //   <Box
  //     ref={columnRef}
  //     {...props}
  //   >
  //     <Transition
  //       items={items} keys={item => item.key}
  //       from={{ transform: 'translate3d(0,-4000px,0)' }}
  //       enter={{ transform: 'translate3d(0,0px,0)' }}
  //       // leave={{ transform: 'translate3d(0,-40px,0)' }}
  //       reset
  //     >
  //       {item => props => <div style={props}>{item}</div>}
  //     </Transition>
  //   </Box>
  // )
}

export default AnimatedColumn
