const buttons = [
  {
    char: 'lt',
    keyMap: 188,
  },
  {
    char: 'rt',
    keyMap: 190,
  },
]

export default buttons
