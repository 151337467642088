import { keyframes } from 'styled-components'

export const scrollUp = ({ th }) => keyframes`
  0% { 
    transform: translateY(0);
  }
	100% { 
    transform: translateY(calc(-${th}px));
  }
`

export const scrollDown = ({ th }) => keyframes`
  0% { 
    transform: translateY(-100vh);
  }
	100% { 
    transform: translateY(calc(${th}px));
  }
`

// calc(100vh / 4 * 6)

// transform: translateY(calc(268px * 4));
// transform: translateY(calc(${heigth} * 5));
