const buttons = [
  {
    char: 'a',
    title: 'select',
    timed: 1000,
    // action: 'SELECT',
    keyMap: 65,
  },
]

export default buttons
