export const SHOW_PORTAL = 'SHOW_PORTAL'
export const HIDE_PORTAL = 'HIDE_PORTAL'

export const SHOW_CONFIGURATION = 'SHOW_CONFIGURATION'
export const SHOW_SUBSCRIPTION = 'SHOW_SUBSCRIPTION'

export const showPortal = () => {
  return {
    type: SHOW_PORTAL,
  }
}

export const hidePortal = () => {
  return {
    type: HIDE_PORTAL,
  }
}

export const showConfiguration = () => {
  return {
    type: SHOW_CONFIGURATION,
  }
}

export const showSubscription = () => {
  return {
    type: SHOW_SUBSCRIPTION,
  }
}
