import styled from 'styled-components'

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em 1em 0.5em 0.5em;
  line-height: 1;
  background: none;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
  border-top-right-radius: 30px;
  z-index: 1020;
`

const Icon = styled.svg`
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
`

const Span = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`

CloseButton.Icon = Icon
CloseButton.Span = Span

export default CloseButton
