import find from 'lodash/find'
import { push } from 'connected-react-router'

import {
  createServerFromCart,
  paymentRequest,
  requestCart,
  requestRenewCart,
  updateFailedCart,
  updateSuccessfullCart,
} from './repository'
import { fetchAllConfigurations } from '../rent/repository'

import {
  failedConfigurationRequest,
  startConfigurationRequest,
  successConfigurationRequest,
} from '../rent/actions'

import { getMyServer } from '../server/actions'

import { messengerAction } from '../messages/actions'

export const POST_CART_REQUEST = 'cart/POST_CART_REQUEST'
export const POST_CART_SUCCESS = 'cart/POST_CART_SUCCESS'
export const POST_CART_FAILURE = 'cart/POST_CART_FAILURE'

export const POST_PAYMENT_REQUEST = 'cart/POST_PAYMENT_REQUEST'
export const POST_PAYMENT_FAILURE = 'cart/POST_PAYMENT_FAILURE'
export const POST_PAYMENT_SUCCESS = 'cart/POST_PAYMENT_SUCCESS'

export const POST_START_SERVER_SETUP = 'server/POST_SETUP'
export const POST_SERVER_SETUP_SUCCESSFULLY = 'server/POST_SETUP_SUCCESSFULLY'
export const POST_SERVER_SETUP_FAILED = 'server/POST_SETUP_FAILED'

export const POST_ORDER_REQUEST = 'order/POST_REQUEST'
export const POST_ORDER_SUCCESS = 'order/POST_SUCCESS'
export const POST_ORDER_FAILURE = 'order/POST_FAILURE'

export const RENEW_SUBSCRIPTION = 'order/RENEW_SUBSCRIPTION'

export const LOCATION_UNAVAILABLE = 'rent/LOCATION_UNAVAILABLE'
export const LOCATION_AVAILABLE = 'rent/LOCATION_AVAILABLE'

export const SET_PRODUCT_LISTINGS = 'cart/PRODUCT_LISTINGS'

/**
 * Start cart process
 */
export function onCartRequest() {
  return {
    type: POST_CART_REQUEST,
  }
}
/**
 * Fullfill cart process
 */
export function successCartRequest(data) {
  return {
    type: POST_CART_SUCCESS,
    payload: data,
  }
}

/**
 * Failed cart process
 */
export function failedCartRequest(error) {
  return {
    type: POST_CART_FAILURE,
    payload: error,
  }
}

/**
 * Fullfill cart process
 */
export function successOrderRequest(data) {
  return {
    type: POST_ORDER_SUCCESS,
    payload: data,
  }
}

/**
 * Failed cart process
 */
export function failedOrderRequest() {
  return {
    type: POST_ORDER_FAILURE,
  }
}

export function startPaymentRequest() {
  return { type: POST_PAYMENT_REQUEST }
}

export function successPaymentRequest(payload) {
  return {
    type: POST_PAYMENT_SUCCESS,
    payload,
  }
}

export function placeOrderRequestFailure(error, order) {
  updateFailedCart(order)
    .then((response) => console.log('failedCart', response))
    .catch((result) => console.log('failedCartError', result))

  return {
    type: POST_ORDER_FAILURE,
    payload: error,
  }
}

// UPDATE FOR FailedMessage
// export function placeOrderRequestFailure(error, order) {
//   return (dispatch) =>
//     updateFailedCart(order)
//       .then((response) => {
//         if (response?.message) dispatch(messengerAction(response.data.message, 'updateFailedCart'))
//       })
//       .catch((response) => {
//         if (response?.data?.message)
//           dispatch(messengerAction(error.data.message, 'updateFailedCart'))
//       })
//       .finally(() =>
//         dispatch({
//           type: POST_ORDER_FAILURE,
//           payload: error,
//         })
//       )
// }

export function placeOrderRequest() {
  return {
    type: POST_ORDER_REQUEST,
  }
}

export function placeOrderRequestSuccess() {
  return {
    type: POST_ORDER_SUCCESS,
  }
}

export function startServerSetup() {
  return {
    type: POST_START_SERVER_SETUP,
  }
}

export function serverSetupSuccessfully(payload) {
  return {
    type: POST_SERVER_SETUP_SUCCESSFULLY,
    payload,
  }
}
export function serverSetupFailed() {
  return {
    type: POST_SERVER_SETUP_FAILED,
  }
}

// ################################
// RENT
// ################################

export function postPlaceOrder(order) {
  return (dispatch) => {
    dispatch(placeOrderRequest())
    updateSuccessfullCart(order.cart.cart_id, order.user)
      .then((response) => {
        if (response?.message)
          dispatch(messengerAction(response.data.message, 'updateSuccessfullCart'))
        dispatch(placeOrderRequestSuccess())
        createServerFromCart(order.cart.cart_id, order.user)
          .then((data) => {
            if (data?.message) dispatch(messengerAction(data.message, 'createServerFromCart'))
            dispatch(serverSetupSuccessfully(data))
            dispatch(push('/dashboard'))
          })
          .catch((error) => {
            dispatch(serverSetupFailed(error))
            if (error?.data?.message)
              dispatch(messengerAction(error.data.message, 'createServerFromCart'))
          })
      })
      .catch((error) => {
        if (error?.data?.message)
          dispatch(messengerAction(error.data.message, 'updateSuccessfullCart'))
        dispatch(placeOrderRequestFailure(error, order))
      })
  }
}

export function postPaymentRequest(order) {
  return (dispatch) => {
    dispatch(startPaymentRequest())
    if (process.env.REACT_APP_DEBUG) {
      // Post direct to the API
      dispatch(postPlaceOrder(order))
    } else {
      // Show payment UI
      paymentRequest(order)
        .then((response) => {
          dispatch(postPlaceOrder(order))
          if (response?.message) dispatch(messengerAction(response.message, 'paymentRequest'))
        })
        .catch((error) => {
          dispatch(placeOrderRequestFailure(error, order))
          if (error?.message) dispatch(messengerAction(error.message, 'paymentRequest'))
        })
    }
  }
}

// ################################
// RENEW
// ################################

export function postPlaceOrderRenew(order) {
  return (dispatch) => {
    dispatch(placeOrderRequest())
    updateSuccessfullCart(order.cart.cart_id, order.user)
      .then((response) => {
        if (response?.message) dispatch(messengerAction(response.message, 'updateSuccessfullCart'))
        dispatch(placeOrderRequestSuccess())
        createServerFromCart(order.cart.cart_id, order.user)
          .then((data) => {
            if (response?.message)
              dispatch(messengerAction(response.message, 'createServerFromCart'))
            dispatch(serverSetupSuccessfully(data))
            dispatch(getMyServer(order.user, false))
          })
          .catch((error) => {
            dispatch(serverSetupFailed(error))
            if (error?.data?.message)
              dispatch(messengerAction(error.data.message, 'createServerFromCart'))
          })
      })
      .catch((error) => {
        dispatch(placeOrderRequestFailure(error, order))
        if (error?.data?.message)
          dispatch(messengerAction(error.data.message, 'updateSuccessfullCart'))
      })
  }
}

export function postPaymentRequestRenew(order) {
  return (dispatch) => {
    // Start payment process
    dispatch(startPaymentRequest())
    if (process.env.REACT_APP_DEBUG) {
      // Post direct to the API
      dispatch(postPlaceOrderRenew(order))
    } else {
      // Show payment UI
      paymentRequest(order)
        .then((response) => {
          dispatch(postPlaceOrderRenew(order))
          if (response?.message)
            dispatch(messengerAction(response.message, 'paymentRequest - Renew'))
        })
        .catch((error) => {
          dispatch(placeOrderRequestFailure(error, order))
          if (error?.message) dispatch(messengerAction(error.message, 'paymentRequest - Renew'))
        })
    }
  }
}

// ################################
// CART PROCESS
// ################################

export function startCartProcess(locationId, order, user, products) {
  return (dispatch) => {
    dispatch(onCartRequest())
    requestCart(
      {
        location_id: locationId,
        configuration_microsoft_store_id: order.microsoft_store.id,
      },
      user
    )
      .then((response) => {
        dispatch(successCartRequest(response.data))
        if (response?.message) dispatch(messengerAction(response.message, 'requestCart'))

        const productId = order.microsoft_store.microsoft_product_id
        let storeId = false
        if (products) {
          storeId = find(products, (prod) => prod.inAppOfferToken === productId)
        }
        if (process.env.REACT_APP_DEBUG) {
          storeId = order.microsoft_store.microsoft_store_id
        }
        if (storeId) {
          dispatch(postPaymentRequest({ ...order, user, storeId, cart: response.data.payload }))
        } else {
          dispatch(failedCartRequest('noStoreId'))
        }
      })
      .catch((error) => {
        dispatch(failedCartRequest(error))
        if (error?.message) dispatch(messengerAction(error.message, 'requestCart'))
      })
  }
}

export function startRenewCartProcess(server, user, products) {
  return (dispatch) => {
    dispatch(startConfigurationRequest())
    fetchAllConfigurations(server.product, user)
      .then((response) => {
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'fetchAllConfigurations - Renew'))

        dispatch(successConfigurationRequest(response.data.payload))
        const productId =
          response.data.payload.configurations[0].microsoft_store.microsoft_product_id
        let storeId = false
        if (products) {
          storeId = find(products, (prod) => prod.inAppOfferToken === productId)
        }
        if (process.env.REACT_APP_DEBUG) {
          storeId = response.data.payload.configurations[0].microsoft_store.microsoft_store_id
        }
        dispatch(onCartRequest())
        requestRenewCart(
          {
            configuration_microsoft_store_id:
              response.data.payload.configurations[0].microsoft_store.id,
            server_id: server.id,
          },
          user
        )
          .then((result) => {
            dispatch(successCartRequest(result.data))
            if (result?.data?.message)
              dispatch(messengerAction(result.data.message, 'requestRenewCart'))
            dispatch(
              postPaymentRequestRenew({
                cart: result.data.payload,
                server,
                user,
                configuration: response.data.payload.configurations[0],
                storeId,
              })
            )
          })
          .catch((error) => {
            dispatch(failedCartRequest(error))
            if (error?.response?.data?.message) {
              dispatch(messengerAction(error.response.data.message, 'requestRenewCart'))
            }
          
          })
      })
      .catch((error) => {
        dispatch(failedConfigurationRequest(error))
        dispatch(failedCartRequest(error))
        if (error?.data?.message)
          dispatch(messengerAction(error.data.message, 'fetchAllConfigurations - Renew'))
      })
  }
}

export function setProducts(payload) {
  return {
    type: SET_PRODUCT_LISTINGS,
    payload,
  }
}

// export function failedProducts(payload) {
//   console.log(payload)
// }
