/* eslint-disable no-console */
import axios from 'axios'

export function fetchMyServer(user) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}servers`, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function startMyServer(server, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_WEB_API_URL}server/${server.id}/start`,
        {},
        {
          headers: {
            email: user.email,
            hash: user.authToken,
          },
        }
      )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

export function stopMyServer(server, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_WEB_API_URL}server/${server.id}/stop`,
        {},
        {
          headers: {
            email: user.email,
            hash: user.authToken,
          },
        }
      )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

export function restartMyServer(server, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_WEB_API_URL}server/${server.id}/restart`,
        {},
        {
          headers: {
            email: user.email,
            hash: user.authToken,
          },
        }
      )
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

export function postUpdateServerConfiguration(config, user) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_WEB_API_URL}server/${config.serverId}`, config, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

export function fetchRandomsForConfiguration(user) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}server/randomInfo`, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
