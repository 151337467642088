import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

import { scrollUp, scrollDown } from './KeyFrames'

const ColumnTransform = styled(Flex)(
  (props) => css`
    > div:nth-child(odd) {
      flex: 1 1 auto;
      animation: ${scrollUp(props)} 50s linear 0s infinite;
    }
    > div:nth-child(even) {
      flex: 1 1 auto;
      animation: ${scrollUp(props)} 50s linear 0s infinite;
      animation-direction: reverse;
      /* animation: ${scrollDown} 40s linear 0s infinite; */
    }
  `
)

export default ColumnTransform
