import styled, { css } from 'styled-components'
import { Box } from 'rebass/styled-components'

const Tag = styled(Box)(
  (props) => css`
    color: #dddddd;
    position: absolute;
    right: ${props.theme.space[3]}px;
    top: ${props.theme.space[3]}px;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    border: 1px solid ${props.theme.colors.danger};
    background-color: ${props.theme.colors.primary};
    padding: 6px 0px 6px 8px;
    font-size: 14px;

    > span {
      background-color: ${props.theme.colors.danger};
      text-transform: uppercase;
      color: ${props.theme.colors.primary};
      padding: 6px 8px;
      margin-left: 8px;
      width: 0;
    }
    ${props.softReminder === 'soft' &&
    css`
      border: 1px solid ${props.theme.colors.brand};
      > span {
        background-color: ${props.theme.colors.brand};
      }
    `}
  `
)

export default Tag
