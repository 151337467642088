/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components'
import { Image } from 'rebass/styled-components'

const Logo = styled(Image)(
  (props) => css`
    width: 40%;
  `
)

Logo.defaultProps = {
  m: 0,
  p: 0,
  pb: 4,
}

export default Logo
