import React from "react"
import PropTypes from "prop-types"
import StyledNavButton from "../styled/StyledNavButton"

const NavButton = ({
  direction,
  disabled,
  onClick,
  ...props
}) => {
  

  return (
    <StyledNavButton direction={direction} disabled={disabled} {...props} onClick={onClick} />
  )
}

NavButton.defaultProps = {
  direction: "left",
  disabled: false,
  onClick: () => {},
}

NavButton.propTypes = {
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default NavButton