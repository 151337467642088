import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import uiReducer from './ui/reducer'
import controlsReducer from './controls/reducer'
import serverReducer from './server/reducer'
import rentReducer from './rent/reducer'
import userReducer from './user/reducer'
import cartReducer from './cart/reducer'
import messageReducer from './messages/reducer'

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    ui: uiReducer,
    controls: controlsReducer,
    server: serverReducer,
    rent: rentReducer,
    cart: cartReducer,
    message: messageReducer,
  })

export default rootReducer
