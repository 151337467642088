import React from 'react'
import { toast } from 'react-toastify'
import Toast from '../../components/Toast/Toast'

export const SUCCESS_MESSAGE = 'message/SUCCESS'
export const NOTICE_MESSAGE = 'message/NOTICE'
export const ERROR_MESSAGE = 'message/ERROR'
export const INTERNAL_ERROR_MESSAGE = 'message/INTERNAL_ERROR'

export function successMessageAction(message) {
  return (dispatch) => {
    dispatch({
      type: SUCCESS_MESSAGE,
    })
    toast.success(<Toast message={message} />, { position: 'bottom-right' })
  }
}

export function errorMessageAction(message) {
  return (dispatch) => {
    dispatch({
      type: ERROR_MESSAGE,
    })
    toast.error(<Toast message={message} />, { position: 'bottom-right' })
  }
}

export function messengerAction(messageObj, initiator) {
  return (dispatch) => {
    switch (messageObj?.level) {
      case 'success':
        if (messageObj?.message || process.env.REACT_APP_DEBUG) {
          toast.success(<Toast message={messageObj.message} />, { position: 'bottom-right' })
        }
        return dispatch({
          type: SUCCESS_MESSAGE,
          payload: { msg: messageObj.message, initiator },
        })
      case 'error':
        if (messageObj?.message || process.env.REACT_APP_DEBUG) {
          toast.error(<Toast message={messageObj.message} />, { position: 'bottom-right' })
        }
        return dispatch({
          type: ERROR_MESSAGE,
          payload: { msg: messageObj.message, initiator },
        })
      case 'notice':
        if (process.env.REACT_APP_DEBUG) {
          toast.warn(<Toast message={messageObj.message} />, { position: 'bottom-right' })
          return dispatch({
            type: NOTICE_MESSAGE,
            payload: { msg: messageObj.message, initiator },
          })
        }
        break
      default:
        break
    }
    return dispatch({ type: INTERNAL_ERROR_MESSAGE, payload: messageObj })
  }
}
