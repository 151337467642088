import {
  POST_CART_REQUEST,
  POST_CART_SUCCESS,
  POST_CART_FAILURE,
  RENEW_SUBSCRIPTION,
  SET_PRODUCT_LISTINGS,
} from './actions'

/** Initial State */
const initialCartState = {
  inProgress: false,
  cartActive: false,
  cartId: -1,
  initRenew: false,
  listings: null,
}

function cartReducer(state = initialCartState, action) {
  switch (action.type) {
    case POST_CART_REQUEST:
      return { ...state, inProgress: true, initRenew: false }
    case POST_CART_SUCCESS:
      return {
        ...state,
        cartId: action.payload.payload.cart_id,
        inProgress: false,
        cartActive: true,
      }
    case POST_CART_FAILURE:
      return { ...state, cartId: -1, inProgress: false, cartActive: false }
    case RENEW_SUBSCRIPTION:
      return { ...state, initRenew: true }
    case SET_PRODUCT_LISTINGS:
      return { ...state, listings: action.payload }
    default:
      return state
  }
}

export default cartReducer
