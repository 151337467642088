import io from 'socket.io-client'

export const initiateSocket = (serviceId, websocket) => {
  const socket = io(`${websocket}`, {
    path: '/ws/legacy/socket.io',
    reconnectionAttempts: 7,
    reconnectionDelay: 300,
    reconnectionDelayMax: 7000,
    randomizationFactor: 0.7,
    timeout: 10000,
    transports: ['websocket', 'polling'],
  })

  // if (socket && serviceId) socket.emit('openService', )
  socket.on('connect', () => {
    socket.emit('openService', serviceId)
  })

  return socket
}

export const disconnectSocket = (socket) => {
  if (socket) socket.disconnect()
}

export const subscribeToSensors = (socket, cb) => {
  if (!socket) return true

  socket.on('data', (type, id, data) => {
    return cb({ type, id, data })
  })
  return false
}
