import React from 'react'
import ReactDOM from 'react-dom'

import { Image } from 'rebass/styled-components'
import { Container, Content, Wrapper, Display } from './styled'

import gportalLogo from '../../assets/logo.svg'

const modalRoot = document.getElementById('portal-root')

const PortalContent = ({ ariaLabel, content, displayContent, role, portal }) =>
  ReactDOM.createPortal(
    <Container aria-modal="true" aria-label={ariaLabel} role={role} tabIndex="-1">
      <Content ref={portal}>
        <Image
          src={gportalLogo}
          mx="100px"
          mt="40px"
          sx={{
            width: '50px',
          }}
        />
        <Wrapper width={[1]} flexGrow="1">
          {content}
        </Wrapper>
      </Content>
      <Display>
        <Image width={1 / 3} sx={{ borderRadius: '8px' }} src={displayContent} />
      </Display>
    </Container>,
    modalRoot
  )

export default PortalContent
