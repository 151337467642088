import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const SelectionWrapper = styled(Flex)(
  (props) => css`
    position: relative;
    padding: 1px;
    /* background-color: red; */
    border-radius: 5px;

    ${props.isSelected &&
    css`
      background-color: ${props.theme.colors.brand};
    `}
  `
)

export default SelectionWrapper
