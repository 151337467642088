import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Wrapper = styled(Flex)(
  (props) => css`
    position: relative;
    padding: 0 86px;
        display: flex;
    overflow: scroll;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scroll-margin: 0;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start end;
    ::-webkit-scrollbar {
      display: none;
    }
    /* :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20%;
      height: 100%;
      background-image: linear-gradient(to left, transparent, ${props.theme.colors.background});
      opacity: 1;
      z-index: 1001;
    }
    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-image: linear-gradient(to right, transparent, ${props.theme.colors.background});
      opacity: 1;
    } */
  `
)

export default Wrapper
