import styled, { css } from 'styled-components'
import { Text } from 'rebass/styled-components'

const StyledNumberBubble = styled(Text)(
  (props) => css`
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${props.theme.colors.lightBlue};
    font-weight: 700;
    text-align: center;
    color: black;
    margin-right: 10px;
  `
)

export default StyledNumberBubble
