import React from 'react'
import PropTypes from 'prop-types'

import { Input } from '@rebass/forms/styled-components'
import Error from './components/Error'

const FormField = ({ form, meta, field, type, buttonControl }) => {
  return (
    <>
      <Input
        name={field.name}
        type={type}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        onFocus={buttonControl}
        value={form.values[field.name]}
        autocomplete="off"
        variant={meta.error && meta.touched ? 'inputInvalid' : 'input'}
      />
      {meta.error && meta.touched ? <Error message={meta.error} /> : null}
    </>
  )
}

FormField.defaultProps = {
  type: 'input',
  buttonControl: () => true,
}

FormField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({
      adminPassword: PropTypes.string,
      serverId: PropTypes.number,
      serverName: PropTypes.string,
      serverPassword: PropTypes.string,
      slotSize: PropTypes.number,
    }),
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string,
  buttonControl: PropTypes.func,
}

export default FormField
