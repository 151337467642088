import React from 'react'
import { Login as LoginLayout } from '../../layouts'

import { ImageColumn, ColumnTransform } from '../../components'

const Splash = () => {
  return (
    <LoginLayout sx={{ overflow: 'hidden' }}>
      <ColumnTransform flexGrow={1} overflow="hidden" ml="-90px" flex="1 0 110%">
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
        <ImageColumn width={[1 / 10]} p="20px" />
      </ColumnTransform>
    </LoginLayout>
  )
}

export default Splash
