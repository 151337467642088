import styled, { css } from "styled-components";
import Arrow from '../assets/arrow_ddd.svg'


const StyledNavButton = styled.button(
  ({ 
    direction = 'left'
  }) => css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    background-image: url(${Arrow});
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    bottom: 0;
    transform-origin: center;
    ${direction === 'right' ? 'transform: scaleX(-1);' : ''};
    ${direction === 'left' ? 'left: 0' : 'right: 0'};
    ${direction === 'left' ? 'margin-left: 20px' : 'margin-right: 20px'};
    z-index: 1;
    width: 50px;
    height: 60px;

    :disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  `
)

export default StyledNavButton;