/* eslint-disable no-unused-vars */
// styled-components based extension
import styled, { css } from 'styled-components'
import { Image } from 'rebass/styled-components'

const Logo = styled(Image)(
  (props) => css`
    /* width: 150px; */
  `
)

Logo.defaultProps = {
  m: 0,
  py: '2em',
}

export default Logo
