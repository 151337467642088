/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import PropTypes from 'prop-types'

import { Flex, Box, Image, Text } from 'rebass/styled-components'
import Wrapper from './styled/Wrapper'
import SelectionWrapper from './styled/SelectionWrapper'
import ImageContainer from './styled/ImageContainer'

import resource from './messages/en'
import Indicator from './styled/Indicator'
import Selected from './assets/selected.svg'
import NumberBubble from '../NumberBubble/NumberBubble'

const GameListing = ({ games, selectId, select }) => {
  const { i18n, t } = useTranslation()
  i18n.addResources('en', 'gameListing', resource)

  const [focused, setFocused] = useState(0)
  const [gameTitle, setGameTitle] = useState('')

  const handleFocus = (id) => {
    setFocused(id)
    const game = null || find(games, ['id', id])
    if (game) {
      setGameTitle(game.name)
      select(id)
    }
  }

  return (
    <Box>
      <Text variant="uppercaseSmall" px="100px" pt="20px">
        <NumberBubble>
          1
        </NumberBubble>
        {t('gameListing:selectTitle')}
        {gameTitle && ` | ${gameTitle}`}
      </Text>
      <Wrapper>
        <Flex
          width={[1]}
          flexDirection="row"
          // style={{ flex: '1 0 100%', overflowX: 'hidden', overflowY: 'visible' }}
        >
          {games &&
            games.map((game, index) => {
              return (
                <ImageContainer
                  className="focusable"
                  key={`game-${game.id}`}
                  p="20px 14px"
                  id={`game-${index}`}
                  onFocus={() => handleFocus(game.id)}
                  onClick={() => handleFocus(game.id)}
                  isFocused={focused === game.id}
                  isSelected={selectId === game.id}
                  data-type="game"
                  data-id={game.id}
                  data-tv-focus-left={index === 0 ? `#game-${games.length - 1}` : ''}
                  data-tv-focus-right={index === games.length - 1 ? '#game-0' : ''}
                  // width={[1 / 6]}
                  // style={{
                  //   flexShrink: 0,
                  // }}
                >
                  <SelectionWrapper
                    isFocused={focused === game.id}
                    isSelected={selectId === game.id}
                  >
                    <Image
                      width="280px"
                      src={game.images.cover}
                      sx={{
                        borderRadius: '8px',
                      }}
                    />
                    {selectId === game.id && <Indicator src={Selected} />}
                  </SelectionWrapper>
                </ImageContainer>
              )
            })}
        </Flex>
      </Wrapper>
    </Box>
  )
}

GameListing.defaultProps = {
  games: [],
  selectId: null,
}

GameListing.propTypes = {
  games: PropTypes.arrayOf(PropTypes.any),
  selectId: PropTypes.number,
  select: PropTypes.func.isRequired,
}

export default GameListing
