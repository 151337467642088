/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-undef */
import axios from 'axios'

export function fetchAllGames() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}products`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function fetchAllLocations(product) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}locations/${product.id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function fetchAllConfigurations(game, user) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}product/${game.game_key}`, {
        headers: {
          email: user.email,
          hash: user.authToken,
        },
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
