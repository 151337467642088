import styled, { css } from 'styled-components'
import { Box } from 'rebass/styled-components'

const ImageContainer = styled(Box)(
  (props) => css`
    position: relative;
    img {
      border: 1px solid transparent;
    }

    ${props.isSelected &&
    css`
      img {
        /* border: 2px solid ${props.theme.colors.brand}; */
        border: 1px solid transparent;
      }
    `}

    ${props.isFocused &&
    css`
      img {
        /* border: 2px solid ${props.theme.colors.brand}; */
        border: 1px solid transparent;
      }
    `}

    :focus {
      outline: none;
    }
  `
)

export default ImageContainer
