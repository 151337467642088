export const getUser = (store) => store.user
export const getUserLiveState = (store) => store.user.isLive
export const getUserMigrationState = (store) => store.user.isMigrated
export const getUserAuthState = (store) => store.user.isAuthenticated
export const getUserAuthStatus = (store) => store.user.authStarted
export const buttonState = (store) => store.user.buttonState
export const accButtonState = (store) => store.user.accountButtonState
export const shouldMigrate = (store) => store.user.shouldMigrate
export const shouldLogout = (store) => store.user.shouldLogout
export const shouldNavigate = (store) => store.user.shouldNavigate
export const getPushToPortal = (store) => store.user.pushToPortal
export const getMsalUser = (store) => store.user.account
export const getMsalState = (store) => store.user.state
export const getTokenProcess = (store) => store.user.tokenProcess
export const getPurchaseToken = (store) => store.user.purchaseToken
export const getSupportUri = (store) => store.user.supportUri
export const shouldManageSubscriptions = (store) => store.user.shouldManageSubscriptions