import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Text } from 'rebass/styled-components'
import { useTranslation } from 'react-i18next'

import resource from '../messages/en.stats'

const StatText = ({ kind, stat, percent, valued }) => {
  const { t, i18n } = useTranslation()
  i18n.addResources('en', 'stats', resource)

  const formatBytes = (bytes, decimals = 2) => {
    const bytesValue = parseInt(bytes, 10)
    if (bytesValue === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytesValue / k ** i).toFixed(dm))} ${sizes[i]}`
  }

  return (
    <Flex pr="20px">
      <Text
        pr="10px"
        style={{
          whiteSpace: 'nowrap',
        }}
        variant="serverCard"
      >
        {t(`stats:${kind}`, stat)}:
      </Text>
      {!!valued && stat.active !== 0 && (
        <Text variant="serverCard">{`${formatBytes(stat.active.used)}`}</Text>
      )}
      {!!valued && stat.active === 0 && <Text variant="serverCard">0 GB</Text>}
      {!!percent && <Text variant="serverCard">{`${stat.active} %`}</Text>}
    </Flex>
  )
}
StatText.propTypes = {
  stat: PropTypes.shape({
    active: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({ used: PropTypes.number, total: PropTypes.number }),
    ]),
    total: PropTypes.number,
  }).isRequired,
  kind: PropTypes.string.isRequired,
  percent: PropTypes.bool,
  valued: PropTypes.bool,
}
StatText.defaultProps = {
  percent: false,
  valued: false,
}

export default StatText
