export const SET_CONTROLS = 'SET_CONTROLS'
export const RESET_CONTROLS = 'RESET_CONTROLS'
export const UNSET_CONTROLS = 'UNSET_CONTROLS'
export const UPDATE_CONTROLS = 'UPDATE_CONTROLS'
export const DISABLE_CONTROLS = 'DISABLE_CONTROLS'

export const setControls = (buttons) => {
  return {
    type: SET_CONTROLS,
    payload: {
      buttons,
    },
  }
}

export const resetControls = () => {
  return {
    type: RESET_CONTROLS,
  }
}

export const unsetControls = () => {
  return {
    type: UNSET_CONTROLS,
    payload: {
      buttons: [],
    },
  }
}

export const disableControls = () => {
  return {
    type: DISABLE_CONTROLS,
  }
}

export const updateControls = (button) => {
  return {
    type: UPDATE_CONTROLS,
    payload: button,
  }
}
