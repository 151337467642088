import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Text, Flex } from 'rebass/styled-components'
import Scrollbar from 'react-custom-scrollbars'
import caretDown from '../../../assets/ic-caret-down.svg'
import caretUp from '../../../assets/ic-caret-up.svg'

const Dropdown = (props) => {
  const { buttonControl, setFieldValue, options, name, value } = props

  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [caret, setCaret] = useState(caretDown)
  const [border, setBorder] = useState('light')
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (isOpen && typeof window !== 'undefined') {
      const { TVJS } = window
      TVJS.DirectionalNavigation.focusRoot = document.getElementById(name)
    }
    if (!isOpen && typeof window !== 'undefined') {
      const { TVJS } = window
      TVJS.DirectionalNavigation.focusRoot = document.getElementById('portal-root')
    }
  }, [isOpen, name])

  const chooseOption = (value) => {
    return () => {
      setFieldValue(name, value)
      setCurrentValue(value)
      setIsOpen(false)
      // const element = props.options.find((option) => option.id === id)
      // props.setDisplayContent(new props.Component(element))
    }
  }

  const showDropDown = () => {
    return options.map((option) => {
      return (
        <Flex
          sx={{
            height: '3em',
            borderBottom: '0.125em solid',
            borderColor: 'light',
            tabIndex: '0',
          }}
          className="focusable"
          alignItems="center"
          key={option.value}
          onClick={chooseOption(option.value)}
        >
          <Text sx={{ color: 'text', fontSize: '1.25em' }}>{option.label}</Text>
        </Flex>
      )
    })
  }

  const showOrClose = () => {
    if (isOpen) {
      setIsOpen(false)
      setCaret(caretDown)
      setBorder('light')
    } else {
      setIsOpen(true)
      setCaret(caretUp)
      setBorder('transparent')
    }
  }

  return (
    <Flex
      height="2.5em"
      sx={{
        outline: '0',
        color: 'text',
        position: 'relative',
        borderBottom: '0.125em solid',
        borderColor: isFocused ? 'brand' : border,
      }}
      onFocus={() => {
        setIsFocused(true)
        buttonControl()
      }}
      onBlur={() => {
        setIsFocused(false)
      }}
      onClick={showOrClose}
      tabIndex="0"
    >
      <Box
        flexGrow="1"
        sx={{
          padding: '0.5em',
        }}
      >
        <Text variant="inputView">
          {options.find((option) => option.value === currentValue)?.label}
        </Text>
      </Box>

      <Box alignSelf="end">
        <img src={caret} alt="" />
      </Box>

      {isOpen ? (
        <Scrollbar
          style={{
            width: '100%',
            height: options.length < 5 ? `${options.length * 3.125}em` : '10em',
            position: 'absolute',
            top: '2.5em',
            background: '#3b3945',
            zIndex: 1001,
          }}
          /* eslint no-shadow: 0 */
          renderTrackVertical={({ style, ...props }) => {
            return (
              <Box
                style={{
                  backgroundColor: '#302f38',
                  height: '100%',
                  top: 0,
                  right: 5,
                  ...style,
                }}
                /* eslint react/jsx-props-no-spreading: 0 */
                {...props}
              />
            )
          }}
          /* eslint no-shadow: 0 */
          renderThumbVertical={({ style, ...props }) => {
            return (
              <Box
                style={{
                  backgroundColor: '#50ffea',
                  ...style,
                }}
                /* eslint react/jsx-props-no-spreading: 0 */
                {...props}
              />
            )
          }}
        >
          <Box
            id={name}
            sx={{
              paddingLeft: '1.5em',
              paddingRight: '1.5em',
            }}
          >
            {showDropDown()}
          </Box>
        </Scrollbar>
      ) : null}
    </Flex>
  )
}

Dropdown.defaultProps = {
  buttonControl: () => {
    return true
  },
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  buttonControl: PropTypes.func,
}

export default Dropdown
