import styled, { css } from 'styled-components'
import { Box } from 'rebass/styled-components'

const ServerBox = styled(Box)(
  () => css`
    /* padding: 20px; */
    padding: 30px 0;
    scroll-margin-top: 30px;

    &:nth-child(odd) {
      padding-right: 30px;
    }
    &:nth-child(even) {
      padding-left: 30px;
    }
    :focus {
      outline: none;
    }
  `
)

export default ServerBox
