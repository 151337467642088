/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Flex, Text } from 'rebass/styled-components'
import Location from './components/Location'
import Loading from './components/Loading'
import Wrapper from './styled/Wrapper'
import FocusWrap from './styled/FocusWrap'

import resource from './messages/en'
import NumberBubble from '../NumberBubble/NumberBubble'
import NavButton from './components/NavButton'

const Locations = ({ locations, selectLocation, select }) => {
  const { i18n, t } = useTranslation()
  i18n.addResources('en', 'locations', resource)
  const focusRef = useRef(null)
  const [focused, setFocused] = useState()
  const [focusedIndex, setFocusedIndex] = useState(0)

  const handleFocus = (location, index, focusToEl = false) => {
    setFocused(location)
    setFocusedIndex(index)
    select(location.id)

    if (index === 0) {
      focusRef.current.scrollLeft = 0
      focusRef.current.style.transform = 'none'
      focusRef.current.style.paddingRight = '100px'
      focusRef.current.style.transition = 'all 250ms linear'
    } else if (index === 3) {
      if (focusRef.current.style.transform !== 'none') {
        focusRef.current.scrollLeft = 0
        focusRef.current.style.transform = 'none'
        focusRef.current.style.paddingRight = '100px'
        focusRef.current.style.transition = 'all 250ms linear'
        if (typeof focusRef.current.scrollTo === 'function') {
          focusRef.current.scrollTo(0, 0)
        }
      }
    } else if (index === locations.length - 1) {
      focusRef.current.style.transform = 'translateX(-100px)'
      focusRef.current.style.paddingRight = '120px'
      focusRef.current.style.transition = 'all 250ms linear'
    }

    // when navigating with nav buttons, neither focus, nor click event is triggered, so we need to focus manually
    const targetEl = document.querySelector(`#location-${index}`);
    if (focusToEl && targetEl) {
      targetEl.focus();
    }

    // in case the focused element is partly visible, scroll to it
    if (targetEl) {
      const focusedElRect = targetEl.getBoundingClientRect()
      const focusWrapRect = focusRef.current.getBoundingClientRect()
      if (focusedElRect.right > focusWrapRect.right) {
        focusRef.current.scrollLeft += focusedElRect.right - focusWrapRect.right + 100
      } else if (focusedElRect.left < focusWrapRect.left) {
        focusRef.current.scrollLeft -= focusWrapRect.left - focusedElRect.left + 100
      }
    }
  }

  const navToLeft = () => {
    if (focused == null) {
      // force the first element to be focused
      handleFocus(locations[0], 0, true)
      return;
    }

    if (focusedIndex === 0) {
      // loop back to the end
      handleFocus(locations[locations.length - 1], locations.length - 1, true);
      return;
    }

    handleFocus(locations[focusedIndex - 1], focusedIndex - 1, true);
  }

  const navToRight = () => {
    if (focused == null) {
      // force the first element to be focused
      handleFocus(locations[0], 0, true)
      return;
    }

    if (focusedIndex === locations.length - 1) {
      // loop back to the beginning
      handleFocus(locations[0], 0, true);
      return;
    }

    handleFocus(locations[focusedIndex + 1], focusedIndex + 1, true);
  }

  return (
    <Flex flexDirection="column">
      <Text variant="uppercaseSmall" px="100px" pt="20px">
        <NumberBubble>
          2
        </NumberBubble>
        {t('locations:selectLocation')}
      </Text>
      <Wrapper px="100px">
        <NavButton
          direction="left"
          disabled={locations == null || locations.length === 0}
          onClick={navToLeft}
        />

        <FocusWrap ref={focusRef}>
          {locations &&
            locations.map((location, index) => (
              <Location
                className="focusable"
                key={`location-${location.countryCode}-${location.key}`}
                id={`location-${index}`}
                location={location}
                sx={{ borderColor: 'white' }}
                isSelected={selectLocation && parseInt(selectLocation, 10) === location.id}
                onFocus={() => handleFocus(location, index)}
                onClick={() => handleFocus(location, index)}
                isFocused={focused && focused.id === location.id}
                dataTvFocusLeft={index === 0 ? `#location-${locations.length - 1}` : ''}
                dataTvFocusRight={index === locations.length - 1 ? '#location-0' : ''}
              />
            ))}
          {!locations.length && <Loading />}
        </FocusWrap>

        {focused && focused.location && <p>{focused.id}</p>}

        <NavButton
          direction="right"
          disabled={locations == null || locations.length === 0}
          onClick={navToRight}
        />
      </Wrapper>
    </Flex>
  )
}

Locations.defaultProps = {
  locations: [],
  selectLocation: 0,
}

Locations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.any),
  selectLocation: PropTypes.number,
  select: PropTypes.func.isRequired,
}

export default Locations
