/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react'
import PropTypes from 'prop-types'

import { Keyframes } from 'react-spring/renderprops'
import { Card, Flex } from 'rebass/styled-components'

const Script = Keyframes.Spring(async (next) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    await next({
      from: {
        background: 'linear-gradient(90deg, #485a6a 0%, #3c4b58)',
      },
      to: {
        background: 'linear-gradient(90deg, #485a6a 100%, #3c4b58)',
      },
      reset: true,
      config: { duration: 2000 },
    })
  }
})

const Loading = (props) => {
  const { className } = props
  return (
    <Card
      width={[1 / 6]}
      sx={{
        backgroundColor: 'primary',
        p: '22px 20px',
        marginRight: '28px',
        borderRadius: '8px',
      }}
      className={className}
    >
      <Flex flexDirection="row" alignItems="center">
        {/* <Text sx={{ fontSize: '24px', fontWeight: 700, color: 'text', mx: '10px' }}>EMPTY</Text> */}
        <Script>
          {(styles) => (
            <Flex
              width={[1]}
              height="17px"
              mb="14px"
              style={{
                borderRadius: '4px',
                ...styles,
              }}
            />
          )}
        </Script>
      </Flex>
      <Script>
        {(styles) => (
          <Flex
            width={[3 / 4]}
            height="14px"
            style={{
              borderRadius: '4px',
              ...styles,
            }}
          />
        )}
      </Script>
    </Card>
  )
}

Loading.defaultProps = {
  className: '',
}

Loading.propTypes = {
  className: PropTypes.string,
}

export default Loading
