import { SHOW_PORTAL, HIDE_PORTAL, SHOW_SUBSCRIPTION, SHOW_CONFIGURATION } from './actions'

const initialState = {
  portalVisible: false,
  showConfiguration: false,
  showSubscription: false,
  initializing: false,
  initialized: false,
}

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PORTAL:
      return {
        ...state,
        portalVisible: true,
        initializing: true,
        initialized: false,
      }
    case HIDE_PORTAL:
      return {
        ...state,
        portalVisible: false,
        showConfiguration: false,
        showSubscription: false,
        initializing: false,
        initialized: false,
      }
    case SHOW_CONFIGURATION:
      return {
        ...state,
        portalVisible: true,
        showConfiguration: true,
        showSubscription: false,
        initializing: true,
        initialized: true,
      }
    case SHOW_SUBSCRIPTION:
      return {
        ...state,
        portalVisible: true,
        showConfiguration: false,
        showSubscription: true,
        initializing: true,
        initialized: true,
      }
    default:
      return state
  }
}

export default uiReducer
