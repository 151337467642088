import * as yup from 'yup'
import React from 'react'
import { Trans } from 'react-i18next'

// @see https://react.i18next.com/latest/trans-component
const validationSchema = yup.object().shape({
  serverName: yup.string().required(<Trans i18nKey="configurationform:required">{[]}</Trans>),
  // serverPassword: yup
  //   .string()
  //   .min(
  //     4,
  //     <Trans i18nKey="configurationform:passwordMinLength" values={{ type: 'Server' }}>
  //       {[<b key={uniqueId()} />]}
  //     </Trans>
  //   )
  //   .required(<Trans i18nKey="configurationform:required">{[]}</Trans>),
  // adminPassword: yup
  //   .string()
  //   .min(
  //     4,
  //     <Trans i18nKey="configurationform:passwordMinLength" values={{ type: 'Admin' }}>
  //       {[<b key={uniqueId()} />]}
  //     </Trans>
  //   )
  //   .required(<Trans i18nKey="configurationform:required">{[]}</Trans>),
})

export default validationSchema
