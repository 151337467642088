import React, { useState, useEffect, Fragment } from 'react'
import { uniqueId } from 'lodash'

import { Flex, Image } from 'rebass/styled-components'

import GPCoverImage from '../../assets/games/UWP_Startscreen_0000_GP.png'
import ConanImage from '../../assets/games/UWP_Startscreen_0008_conan.png'
import AstroneerImage from '../../assets/games/UWP_Startscreen_0012_astroneer.png'
import AssettoImage from '../../assets/games/UWP_Startscreen_0013_assetto-corsa.png'
import CitadelImage from '../../assets/games/UWP_Startscreen_0014_citadel.png'
import MarsImage from '../../assets/games/UWP_Startscreen_0015_meoriesofmars.png'
import SpaceImage from '../../assets/games/UWP_Startscreen_0016_space-engineers.png'
import PixarkImage from '../../assets/games/UWP_Startscreen_0017_Pixark.png'

const ImageColumn = () => {
  const [stateArray, setStateArray] = useState()

  const shuffle = (inArr) => {
    const array = [...inArr]
    let currentIndex = array.length

    while (currentIndex !== 0) {
      const randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      const temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  useEffect(() => {
    const coverArray = [
      ConanImage,
      AstroneerImage,
      AssettoImage,
      CitadelImage,
      MarsImage,
      SpaceImage,
      PixarkImage,
    ]
    setStateArray(shuffle(coverArray))
  }, [])

  return (
    <Flex flexDirection="column">
      {stateArray &&
        stateArray.map((coverImage) => (
          <Fragment key={uniqueId()}>
            <Image src={coverImage} />
            <Image src={GPCoverImage} />
          </Fragment>
        ))}
      {stateArray &&
        stateArray.map((coverImage) => (
          <Fragment key={uniqueId()}>
            <Image src={coverImage} />
            <Image src={GPCoverImage} />
          </Fragment>
        ))}
    </Flex>
  )
}

export default ImageColumn
