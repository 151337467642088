import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const AnimationContainer = styled(Flex)(
  () => css`
    .loading-container-normal {
      position: relative;
      height: 100%;
      width: 100%;
    }

    /* for aligning ALL elements centered */
    .loading-container-normal .loading-anim-wrapper-normal {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    }

    /* =================== for the type blocking ===================  */

    /* for the container to gain the width and height of the wrapper outside of the component */
    .loading-container-blocking {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 100;
      backdrop-filter: blur(5px);
    }

    /* for aligning ALL elements centered */
    .loading-container-blocking .loading-anim-wrapper-blocking {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    }

    .loading-title {
      text-transform: uppercase;
      font-weight: bold;
    }

    // css 3 loading animation styles
    #emxv99o7iw93 {
      animation: emxv99o7iw93_c_o 2400ms linear infinite normal forwards;
    }

    @keyframes emxv99o7iw93_c_o {
      0% {
        opacity: 1;
      }
      12.500000% {
        opacity: 0;
      }
      41.666667% {
        opacity: 0;
      }
      54.166667% {
        opacity: 1;
      }
      66.666667% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    #emxv99o7iw94_tr {
      animation: emxv99o7iw94_tr__tr 2400ms linear infinite normal forwards;
    }

    @keyframes emxv99o7iw94_tr__tr {
      0% {
        transform: translate(147.001381px, 18.305599px) rotate(45deg);
      }
      100% {
        transform: translate(147.001381px, 18.305599px) rotate(765deg);
      }
    }

    #emxv99o7iw95_ts {
      animation: emxv99o7iw95_ts__ts 2400ms linear infinite normal forwards;
    }

    @keyframes emxv99o7iw95_ts__ts {
      0% {
        transform: translate(114px, 19.0604px) scale(1.17, 1.17);
      }
      8.333333% {
        transform: translate(114px, 19.0604px) scale(1.3, 1.3);
      }
      16.666667% {
        transform: translate(114px, 19.0604px) scale(1.17, 1.17);
      }
      25% {
        transform: translate(114px, 19.0604px) scale(1.04, 1.04);
      }
      33.333333% {
        transform: translate(114px, 19.0604px) scale(1.17, 1.17);
      }
      41.666667% {
        transform: translate(114px, 19.0604px) scale(1.3, 1.3);
      }
      50% {
        transform: translate(114px, 19.0604px) scale(1.17, 1.17);
      }
      58.333333% {
        transform: translate(114px, 19.0604px) scale(1.04, 1.04);
      }
      66.666667% {
        transform: translate(114px, 19.0604px) scale(1.17, 1.17);
      }
      75% {
        transform: translate(114px, 19.0604px) scale(1.3, 1.3);
      }
      83.333333% {
        transform: translate(114px, 19.0604px) scale(1.17, 1.17);
      }
      91.666667% {
        transform: translate(114px, 19.0604px) scale(1.3, 1.3);
      }
      100% {
        transform: translate(114px, 19.0604px) scale(1.17, 1.17);
      }
    }

    #emxv99o7iw96 {
      animation: emxv99o7iw96_c_o 2400ms linear infinite normal forwards;
    }

    @keyframes emxv99o7iw96_c_o {
      0% {
        opacity: 1;
      }
      20.833333% {
        opacity: 0.5;
      }
      41.666667% {
        opacity: 1;
      }
      70.833333% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }

    #emxv99o7iw97_to {
      animation: emxv99o7iw97_to__to 2400ms linear infinite normal forwards;
    }

    @keyframes emxv99o7iw97_to__to {
      0% {
        transform: translate(45px, 16.9979px);
      }
      20.833333% {
        transform: translate(45px, 10px);
      }
      45.833333% {
        transform: translate(45px, 17px);
      }
      70.833333% {
        transform: translate(45px, 30px);
      }
      100% {
        transform: translate(45px, 17px);
      }
    }

    #emxv99o7iw98_to {
      animation: emxv99o7iw98_to__to 2400ms linear infinite normal forwards;
    }

    @keyframes emxv99o7iw98_to__to {
      0% {
        transform: translate(10.5px, 19.5px);
      }
      45.833333% {
        transform: translate(8.5px, 19.5px);
      }
      70.833333% {
        transform: translate(12.5px, 19.5px);
      }
      100% {
        transform: translate(10.5px, 19.5px);
      }
    }

    #emxv99o7iw98 {
      animation: emxv99o7iw98_c_o 2400ms linear infinite normal forwards;
    }

    @keyframes emxv99o7iw98_c_o {
      0% {
        opacity: 1;
      }
      45.833333% {
        opacity: 0.25;
      }
      100% {
        opacity: 1;
      }
    }
  `
)

export default AnimationContainer
