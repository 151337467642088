import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Content = styled(Flex)(
  (props) => css`
    flex-direction: column;
    width: 35%;
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 1015;
    padding: 0 0 100px 0;

    background-color: ${props.theme.colors.gradientFrom};
    background-image: linear-gradient(
      to right,
      ${props.theme.colors.gradientFrom},
      ${props.theme.colors.gradientTo} 98%
    );
  `
)

export default Content
