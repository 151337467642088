export const buttons = [
  {
    char: 'a',
    title: 'stop',
    // timed: 1000,
    action: 'myserver/STOP_SERVER',
    keyMap: 65,
  },
  {
    char: 'y',
    title: 'configure',
    action: 'SHOW_CONFIGURATION',
    keyMap: 89,
    // disabled: true,
  },
  {
    char: 'x',
    title: 'Advanced settings',
    action: 'myserver/GET_SETTINGS_LINK_REQUEST',
    keyMap: 88,
  },
]

export const stoppedButtons = [
  {
    char: 'a',
    title: 'start',
    // timed: 1000,
    action: 'myserver/START_SERVER',
    keyMap: 65,
  },
  {
    char: 'y',
    title: 'configure',
    action: 'SHOW_CONFIGURATION',
    keyMap: 89,
  },
  {
    char: 'x',
    title: 'Advanced settings',
    action: 'myserver/GET_SETTINGS_LINK_REQUEST',
    keyMap: 88,
  },
]

export const expiredButtons = [
  {
    char: 'a',
    title: 'reactivate',
    timed: 1000,
    action: 'order/RENEW_SUBSCRIPTION',
    keyMap: 65,
  },
]

export default buttons
