import { find } from 'lodash'

import {
  GET_MYSERVER_REQUEST,
  GET_MYSERVER_SUCCESS,
  GET_WEBINTERFACE_LINK_SUCCESS,
  CLEAR_WEBINTERFACE_LINK,
  SET_CURRENT_SERVER_SUCCESS,
  RESTART_SERVER,
  STOP_SERVER,
  START_SERVER,
  POST_RESTART_SERVER_SUCCESS,
  POST_START_SERVER_SUCCESS,
  POST_STOP_SERVER_SUCCESS,
  UPDATE_SERVER_ONLINE_STATE,
  CONFIG_GET_RANDOM_INPUTS,
  CONFIG_SUCCESS_RANDOM_INPUTS,
  CONFIG_FAILED_RANDOM_INPUTS,
  GET_SETTINGS_LINK_REQUEST,
  GET_SETTINGS_LINK_SUCCESS,
  GET_SETTINGS_LINK_FAILURE,
} from './actions'

const InitialState = {
  isFetching: false,
  server: [],
  reflink: '',
  selectedServer: undefined,
  restarting: false,
  starting: false,
  stopping: false,
  fetchingRandom: false,
  randomObj: {},
  isFetchingSettings: false,
}

const serverReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_MYSERVER_REQUEST:
      return { ...state, isFetching: true }
    case GET_WEBINTERFACE_LINK_SUCCESS:
      return { ...state, reflink: action.payload.payload }
    case CLEAR_WEBINTERFACE_LINK:
      return { ...state, reflink: '' }
    case GET_MYSERVER_SUCCESS:
      if (state.selectedServer?.id) {
        const selected = find(action.payload.data.payload, (o) => o.id === state.selectedServer.id)
        return {
          ...state,
          isFetching: false,
          server: action.payload.data.payload,
          selectedServer: selected,
          restarting: false,
          starting: false,
          stopping: false,
        }
      }
      return {
        ...state,
        isFetching: false,
        server: action.payload.data.payload,
        restarting: false,
        starting: false,
        stopping: false,
      }
    case SET_CURRENT_SERVER_SUCCESS:
      return {
        ...state,
        selectedServer: action.payload,
        restarting: false,
        starting: false,
        stopping: false,
      }
    case RESTART_SERVER:
      return { ...state, restarting: true }
    case POST_RESTART_SERVER_SUCCESS:
      return { ...state, restarting: false }
    case STOP_SERVER:
      return { ...state, stopping: true }
    case POST_STOP_SERVER_SUCCESS:
      return { ...state, stopping: false }
    case START_SERVER:
      return { ...state, starting: true }
    case POST_START_SERVER_SUCCESS:
      return { ...state, starting: false }
    case UPDATE_SERVER_ONLINE_STATE:
      // eslint-disable-next-line no-case-declarations
      const updSrv = state.server.map((srv) => {
        if (srv.id === action.payload.id) {
          return { ...srv, state: action.payload.state }
        }
        return { ...srv }
      })
      if (action.payload.id === state.selectedServer?.id) {
        return {
          ...state,
          server: updSrv,
          selectedServer: { ...state.selectedServer, state: action.payload.state },
        }
      }
      return { ...state, server: updSrv }
    case CONFIG_GET_RANDOM_INPUTS:
      return { ...state, fetchingRandom: true }
    case CONFIG_SUCCESS_RANDOM_INPUTS: {
      const randomObj = {
        serverName: action.payload.data.payload.server_name,
        serverPassword: action.payload.data.payload.server_password,
        adminPassword: action.payload.data.payload.admin_password,
      }
      return { ...state, fetchingRandom: false, randomObj }
    }
    case CONFIG_FAILED_RANDOM_INPUTS:
      return { ...state, fetchingRandom: false, randomObj: {} }
    case GET_SETTINGS_LINK_REQUEST:
      return { ...state, isFetchingSettings: true }
    case GET_SETTINGS_LINK_SUCCESS:
      return { ...state, isFetchingSettings: false }
    case GET_SETTINGS_LINK_FAILURE:
      return { ...state, isFetchingSettings: false }
    default:
      return state
  }
}

export default serverReducer
