import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Container = styled(Flex)(
  (props) => css`
    position: relative;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 2px 2px 2px 0;
    min-height: 260px;

    ${props.selected &&
    css`
      /* border: 2px solid ${props.theme.colors.brand}; */
      /* background: linear-gradient(to right, red, purple); */
      background-image: linear-gradient(-90deg,#ffc75e 0%,#f03da2 100%);
    `}

    border-left: none;

    :focus {
      outline: none !important;
    }

    ${props.expired &&
    props.selected &&
    css`
      background: ${props.theme.colors.danger};
    `}
    border-left: none;

    :focus {
      outline: none !important;
    }
  `
)

Container.defaultProps = {
  my: 2,
}

export default Container
