import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const FocusWrap = styled(Flex)(
  () => css`
    position: relative;
    flex: 1 0 100%;
    overflow-x: hidden;
    overflow-y: visible;
    padding: 20px 100px 0 100px;
    scroll-behavior: smooth;
  `
)

FocusWrap.defaultProps = {}

export default FocusWrap
