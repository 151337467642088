// styled-components based extension
import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Container = styled(Flex)(
  (props) => css`
    flex-grow: 1;
    flex-shrink: 0;
    height: 100vh;
    background-color: ${props.theme.colors.background};
  `
)

Container.defaultProps = {
  mx: 0,
  px: 0,
}

export default Container
