import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { uniqueId, findIndex, debounce } from 'lodash'
import { Flex } from 'rebass/styled-components'

import Gamepad from '../../libs/gamepad/gamepad'
import Menu from './styled/Menu'
import ControlGroup from '../ControlGroup/ControlGroup'

import { main } from '../../models/navigation/main'
import buttons from './controls'

const MainMenu = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [currentLocation, setLocation] = useState(location.pathname)
  const [currentIndex, setIndex] = useState(findIndex(main, { link: location.pathname }))

  const debounceNavigation = debounce((pushObj) => {
    dispatch(push(pushObj))
  }, 333)
  const handleNextRTPress = useCallback(() => {
    const next = currentIndex + 1 < main.length
    if (next) debounceNavigation(main[currentIndex + 1].link)
  }, [currentIndex, debounceNavigation])

  const handlePrevLTPress = useCallback(() => {
    const prev = !!currentIndex
    if (prev) debounceNavigation(main[currentIndex - 1].link)
  }, [currentIndex, debounceNavigation])

  const handleClick = (link) => {
    if (link) dispatch(push(link))
  }

  // const handleButtonPress = useCallback(
  //   ({ keyCode }) => {
  //     if (keyCode === 188 && prev) dispatch(push(main[currentIndex - 1].link))
  //     if (keyCode === 190 && next) dispatch(push(main[currentIndex + 1].link))
  //   },
  //   [dispatch, currentIndex, next, prev]
  // )

  // useEffect(() => {
  //   window.addEventListener('keyup', handleButtonPress)
  //   return () => {
  //     window.removeEventListener('keyup', handleButtonPress)
  //   }
  // }, [handleButtonPress])

  useEffect(() => {
    setLocation(location.pathname)
    setIndex(findIndex(main, { link: location.pathname }))
  }, [location, currentLocation])

  return (
    <Menu>
      {main.map((item) => (
        <Menu.MenuItem
          variant="menu"
          to={{ pathname: item.link }}
          selected={currentLocation === item.link}
          key={uniqueId()}
          onClick={() => handleClick(item.link)}
        >
          {item.label}
        </Menu.MenuItem>
      ))}
      <Flex>
        <ControlGroup buttons={buttons} />
      </Flex>
      <Gamepad onLT={() => handlePrevLTPress()} onRT={() => handleNextRTPress()}>
        <></>
      </Gamepad>
    </Menu>
  )
}

export default MainMenu
