import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import StyledServerBox from './styled/StyledServerBox'

const ServerBox = (props) => {
  const { selected, children } = props
  const scrollRef = useRef()

  useEffect(() => {
    if (selected && scrollRef?.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledServerBox {...props} ref={scrollRef}>
      {children}
    </StyledServerBox>
  )
}

ServerBox.defaultProps = {
  selected: false,
}
ServerBox.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default ServerBox
