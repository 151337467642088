import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Button = styled(Flex)(
  (props) => css`
  flex: 0 0 36px;
  vertical-align: center;
  text-align: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 1;
  font-weight: bold;
  align-items: center;
  align-self: center;
  /* height: fit-content; */
  margin-top: auto;
  margin-bottom: auto;

  ${
    props.color &&
    css`
      color: ${props.color.color};
      background-color: ${props.color.backgroundColor};
    `
  }
  ${
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `
  }
  ${
    props.width &&
    css`
      width: ${props.width};
    `
  }
  ${
    props.height &&
    css`
      height: ${props.height};
    `
  }
  ${
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius};
    `
  }
  ${
    props.borderColor &&
    css`
      border: 1px solid ${props.theme.borderColor};
    `
  }
  ${
    props.lowerOpacity &&
    css`
      opacity: 0.4;
    `
  }
  ${
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `
  }
  ${
    props.basis &&
    css`
      flex-basis: ${props.basis};
    `
  }
  
  ${
    props.isPressed &&
    css`
      background-color: ${props.color.color};
      color: ${props.color.backgroundColor};
    `
  }


  ${
    props.disabled &&
    css`
      opacity: 0.4;
      color: ${props.color.color};
      background-color: ${props.color.backgroundColor};
    `
  }
`
)
Button.defaultProps = {
  mr: '12px',
  p: '10px',
}

export default Button
