import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { EventType } from '@azure/msal-browser'

const MsalEventWrapper = ({ pca, children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    pca.addEventCallback((event) => {
      const { eventType, payload, error } = event

      switch (eventType) {
        case EventType.LOGIN_START: {
          // console.log('payload - loginStart', payload)
          dispatch({ type: 'user/START_LIVE_AUTH' })
          break
        }
        case EventType.LOGIN_SUCCESS: {
          const { account } = payload
          pca.setActiveAccount(account)
          dispatch({
            type: 'user/USER_IS_LIVE',
            payload: account,
          })
          break
        }
        case EventType.LOGIN_FAILURE: {
          // console.log('payload - loginFailure', payload)
          dispatch({ type: 'user/LIVE_AUTH_FAILED' })
          break
        }
        case EventType.ACQUIRE_TOKEN_NETWORK_START: {
          // console.log('payload - acquireTokenNetworkStart', payload)
          break
        }
        case EventType.ACQUIRE_TOKEN_START: {
          // console.log('payload - acquireTokenStart', payload)
          break
        }
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          // console.log('payload - acquireTokenSuccess', payload)
          break
        }
        case EventType.ACQUIRE_TOKEN_FAILURE: {
          // console.log('payload - acquireTokenFailure', payload)
          break
        }
        case EventType.SSO_SILENT_START: {
          // console.log('payload - ssoSilentStart', payload)
          break
        }
        case EventType.SSO_SILENT_SUCCESS: {
          const { account } = payload
          // console.log('payload - ssoSilentSuccess', payload)
          pca.setActiveAccount(account)
          break
        }
        case EventType.SSO_SILENT_FAILURE: {
          // console.log('payload - ssoSilentFailure', payload)
          break
        }
        case EventType.HANDLE_REDIRECT_START: {
          // console.log('payload - handleRedirectStart', payload)
          break
        }
        case EventType.HANDLE_REDIRECT_END: {
          // console.log('payload - handleRedirectEnd', payload)

          if (payload === null && error === null) {
            const accounts = pca.getAllAccounts()
            if (accounts?.length > 0) {
              pca.setActiveAccount(accounts[0])
              dispatch({
                type: 'user/USER_IS_LIVE',
                payload: accounts[0],
              })
            } else {
              dispatch({
                type: 'user/USER_NOT_LIVE',
              })
            }
          }
          break
        }
        case EventType.LOGOUT_START: {
          // console.log('payload - logoutStart', payload)
          break
        }
        case EventType.LOGOUT_SUCCESS: {
          // console.log('payload - logoutSuccess', payload)
          break
        }
        case EventType.LOGOUT_END: {
          // console.log('payload - logoutEnd', payload)
          break
        }
        default:
          break
      }
    })
    return () => {
      pca.removeEventCallback()
    }
  }, [pca, dispatch])

  return <>{children}</>
}

MsalEventWrapper.defaultProps = {}

MsalEventWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pca: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default MsalEventWrapper
