import React from 'react'
import { Image } from 'rebass/styled-components'
import PropTypes from 'prop-types'
import imageWarning from '../../../assets/icons/t-warning.svg'
import ErrorBox from '../styled/ErrorBox'

const Error = ({ message }) => {
  return (
    <ErrorBox>
      <Image src={imageWarning} sx={{ marginRight: '0.5em' }} />
      <span>{message}</span>
    </ErrorBox>
  )
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
}

export default Error
