import { fetchAllGames, fetchAllLocations, fetchAllConfigurations } from './repository'
import { messengerAction } from '../messages/actions'

export const GET_GAMES_REQUEST = 'games/GET_REQUEST'
export const GET_GAMES_FAILURE = 'games/GET_FAILURE'
export const GET_GAMES_SUCCESS = 'games/GET_SUCCESS'
export const CHOOSE_GAME = 'games/CHOOSE'

export const GET_LOCATION_REQUEST = 'location/GET_REQUEST'
export const GET_LOCATION_FAILURE = 'location/GET_FAILURE'
export const GET_LOCATION_SUCCESS = 'location/GET_SUCCESS'
export const CHOOSE_LOCATION = 'location/CHOOSE'

export const GET_CONFIGURATION_REQUEST = 'configuration/GET_REQUEST'
export const GET_CONFIGURATION_FAILURE = 'configuration/GET_FAILURE'
export const GET_CONFIGURATION_SUCCESS = 'configuration/GET_SUCCESS'

export const CLEAR_REDUCER = 'rentReducer/CLEAR'

export function clearRentReducer() {
  return {
    type: CLEAR_REDUCER,
  }
}

/**
 * Start fetching a list of games
 */
export function startGamesRequest() {
  return {
    type: GET_GAMES_REQUEST,
  }
}

/**
 * The fetching was successfully
 * @param games - a list of IGames
 */
export function successGamesRequest(games) {
  return {
    type: GET_GAMES_SUCCESS,
    payload: games,
  }
}

/**
 * The fetching was unsuccessfull
 * @param games - a list of IGames
 */
export function failedGamesRequest() {
  return {
    type: GET_GAMES_FAILURE,
  }
}

/**
 * Start fetching and call failure or success action
 * @param silent - handle the loading
 */
export function getGamesForRent() {
  return (dispatch) => {
    fetchAllGames()
      .then((response) => {
        dispatch(successGamesRequest(response.data))
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'getGamesForRent'))
      })
      .catch((error) => {
        dispatch(failedGamesRequest())
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'getGamesForRent'))
      })
  }
}

/**
 * Start request - important for the loading animation
 */
export function startLocationRequest() {
  return {
    type: GET_LOCATION_REQUEST,
  }
}

/**
 * Start request - important for the loading animation
 */
export function failureLocationRequest(error) {
  return {
    type: GET_LOCATION_FAILURE,
    payload: error,
  }
}

/**
 * Action to save the locations
 * @param locations - a array of locations
 */
export function successLocationRequest(locations) {
  return {
    type: GET_LOCATION_SUCCESS,
    payload: locations,
  }
}

/**
 * Action to fetch the locations
 * @param silent - important for the loading animation
 */
export function getLocations(silent, game) {
  return (dispatch) => {
    if (!silent) {
      dispatch(startLocationRequest())
    }

    fetchAllLocations(game)
      .then((response) => {
        dispatch(successLocationRequest(response.data))
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'fetchAllLocations'))
      })
      .catch((error) => {
        dispatch(failureLocationRequest(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'fetchAllLocations'))
      })
  }
}

export function startConfigurationRequest() {
  return {
    type: GET_CONFIGURATION_REQUEST,
  }
}

export function successConfigurationRequest(configurations) {
  return {
    type: GET_CONFIGURATION_SUCCESS,
    payload: configurations,
  }
}

export function failedConfigurationRequest(configurations) {
  return {
    type: GET_CONFIGURATION_FAILURE,
    payload: configurations,
  }
}

export function fetchConfiguration(silent = false, game, user) {
  return (dispatch) => {
    if (!silent) {
      dispatch(startConfigurationRequest())
    }
    fetchAllConfigurations(game, user)
      .then((response) => {
        dispatch(successConfigurationRequest(response.data.payload))
        if (response.data?.message)
          dispatch(messengerAction(response.data.message, 'fetchConfiguration'))
      })
      .catch((error) => {
        dispatch(failedConfigurationRequest(error))
        if (error?.data?.message)
          dispatch(messengerAction(error.data.message, 'fetchConfiguration'))
      })
  }
}
