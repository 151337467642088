import styled, { css } from 'styled-components'
import { Text } from 'rebass/styled-components'

const Title = styled(Text)(
  (props) => css`
    letter-spacing: 1.5px;
    font-weight: bold;
    line-height: 1;
    ${props.lowerOpacity &&
    css`
      opacity: 0.4;
    `}
  `
)
Title.defaultProps = {
  mr: '28px',
}

export default Title
