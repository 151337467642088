import styled, { css } from 'styled-components'
import { Image } from 'rebass/styled-components'

const Indicator = styled(Image)(
  (props) => css`
    position: absolute;
    top: -14px;
    right: -14px;
    width: 28px;
    height: 28px;
    background-color: ${props.theme.colors.brand};
    border-radius: 50%;
    z-index: 1024;
  `
)

export default Indicator
