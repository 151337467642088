import { createGlobalStyle } from 'styled-components'
/* eslint import/no-named-default: 0 */

export const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: 400;
  }
`

const colors = {
  white: '#ffffff',
  black: '#000000',
  mint: '#04d5bc',
  mintDisable: '#40B2A7',
  red: '#c4293e',
  green: '#45ff8c',
  yellow: '#f5a623',
  primary: '#2f3a44',
  secondary: '#262f37',
  background: '#1e262d',
  buttonBlue: '#33aeed',
  buttonGreen: '#33ed3d',
  buttonRed: '#ed334f',
  buttonYellow: '#ffc818',

  charcoal: '#252a34',
  darkAubergine: '#362c3d',
  error: '#FF536A',

  grey: '#7d8287',
  lightishGrey: '#4E5256',
  lightGrey: '#dddddd',
  darkGrey: '#364757',
  darkBlue: '#1b2229',
  dark: '#1e1e1e',
  light: '#7491ab',
  lightBlue: '#50ffea',
  livegreen: '#107c10',
}
const theme = {
  maxContainerWidth: '1920px',
  fontPrimary: 'Source Sans Pro',
  icons: {
    sizes: ['16px', '24px', '32px'],
  },
  colors: {
    error: colors.error,
    brand: colors.mint,
    brandDisable: colors.mintDisable,
    textDisable: colors.lightishGrey,

    primary: colors.primary,
    secondary: colors.secondary,
    background: colors.background,
    text: colors.white,
    darkGrey: colors.darkGrey,
    lightGrey: colors.lightGrey,
    darkBlue: colors.darkBlue,
    light: colors.light,
    lightBlue: colors.lightBlue,

    buttonBlue: colors.buttonBlue,
    buttonGreen: colors.buttonGreen,
    buttonRed: colors.error,
    buttonYellow: colors.buttonYellow,

    danger: colors.error,
    warning: colors.yellow,
    success: colors.green,
    gradientFrom: colors.charcoal,
    gradientTo: colors.darkAubergine,
    white: colors.white,
    black: colors.black,
    live: colors.livegreen,
  },
  breakpoints: ['576px', '768px', '992px'],
  space: [0, 4, 8, 12, 24],
  fonts: {
    primary: 'Source Sans Pro',
    heading: 'Source Sans Pro',
    text: 'Source Sans Pro',
  },
  text: {
    headline: {
      color: 'text',
      my: 0,
      fontSize: '36px',
      fontWeight: 900,
    },
    headlineSmaller: {
      color: 'text',
      my: 0,
      fontSize: '28px',
      fontWeight: 700,
    },
    headlineLight: {
      color: 'text',
      my: 0,
      fontSize: '36px',
      fontWeight: 400,
    },
    headlineServerCard: {
      color: 'text',
      my: 0,
      fontSize: '28px',
      fontWeight: 700,
    },
    headlineServerCardLight: {
      color: 'text',
      my: 0,
      fontSize: '28px',
      fontWeight: 400,
    },
    uppercaseEntry: {
      fontSize: '14px',
      fontWeight: 700,
      textTransform: 'uppercase',
      color: 'lightGrey',
    },
    uppercaseSmall: {
      color: 'lightGrey',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: 1.2,
      textTransform: 'uppercase',
    },
    lightWiden: {
      color: 'lightGrey',
      fontSize: '18px',
      fontWeight: 300,
      letterSpacing: 1.5,
    },
    serverCard: {
      color: 'text',
      fontSize: '18px',
      fontWeight: 300,
    },
    inlineGreen: {
      color: 'success',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: 1.2,
      textTransform: 'uppercase',
      display: 'inline',
    },
  },
  forms: {
    input: {
      color: 'white',
      border: 'none',
      borderBottom: '2px solid',
      borderColor: 'light',
      height: '40px',
      outline: 'none',
      '&:active, :focus': {
        borderColor: 'lightBlue',
      },
    },
    inputInvalid: {
      color: 'white',
      border: 'none',
      borderBottom: '2px solid',
      borderColor: 'error',
      height: '40px',
      outline: 'none',
      '&:active, :focus': {
        color: 'lightBlue',
      },
    },
    label: {
      color: 'light',
      fontSize: '16px',
      lineHeight: '16px',
      letterSpacing: '1.6px',
      textTransform: 'uppercase',
      paddingTop: '32px',
    },
  },
  buttons: {
    submit: {
      fontFamily: 'Source Sans Pro',
      fontSize: 2,
      fontWeight: 'bold',
      color: 'black',
      bg: 'lightBlue',
      borderRadius: 'default',
      marginTop: '3em',
      textTransform: 'uppercase',
      padding: '20px',
      '&:focus, :active': {
        bg: 'success',
        outline: 0,
      },
    },
    xboxlive: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Source Sans Pro',
      fontSize: 2,
      fontWeight: 'bold',
      color: 'white',
      bg: 'live',
      borderRadius: 'default',
      marginTop: '3em',
      textTransform: 'uppercase',
      padding: '20px',
      '&:focus, :active': {
        bg: '#0e6a0e',
        outline: 0,
      },
    },
    disabled: {
      fontFamily: 'Source Sans Pro',
      fontSize: 2,
      fontWeight: 'bold',
      color: 'textDisable',
      bg: 'brandDisable',
      borderRadius: 'default',
      marginTop: '3em',
      textTransform: 'uppercase',
      padding: '20px',
    },
    link: {
      fontFamily: 'Source Sans Pro',
      fontSize: 2,
      fontWeight: 'bold',
      color: 'lightBlue',
      bg: 'transparent',
      borderRadius: 'default',
      textTransform: 'uppercase',
      '&:focus, :active': {
        color: 'brand',
        outline: 0,
      },
    },
  },
}

export default theme
