import React from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react'
import { createTransport } from '@sentry/core'

/* eslint import/no-extraneous-dependencies: 0 */
// IE / Edge support
import 'react-app-polyfill/stable'

// MSAL imports
import { PublicClientApplication } from '@azure/msal-browser'
import axios from 'axios'
import { msalConfig } from './config/authConfig'

import App from './containers/App'
import * as serviceWorker from './serviceWorker'

function makeMyCustomTransport(options) {
  function makeRequest(request) {
    // this is where your sending logic goes
    const myCustomRequest = {
      body: request.body,
      url: options.url,
    }

    function sendMyCustomRequest(req) {
      return axios.post(`${req.url}`, req.body)
    }

    // you define how `sendMyCustomRequest` works
    return sendMyCustomRequest(myCustomRequest).then((response) => ({
      headers: {
        'x-sentry-rate-limits': response.headers['X-Sentry-Rate-Limits'],
        'retry-after': response.headers['Retry-After'],
      },
      statusCode: response.status ?? 500,
      status: 'success',
    }))
  }


  // `createTransport` takes care of rate limiting and flushing
  return createTransport(options, makeRequest)
}

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn:
    'https://aa41d4973ae54b9189245cfa04203bc0@sentry.g-portal.com/25',
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
        ),
      }),
      new Sentry.Replay()
    ],
  // Performance Monitoring
  tracesSampleRate: 0.05,
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  transport: makeMyCustomTransport,
})

// eslint-disable-next-line import/prefer-default-export
export const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(<App pca={msalInstance} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
