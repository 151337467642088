import { LogLevel } from '@azure/msal-browser'
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '76f9f75a-85cd-45b9-b519-c26e281e989a',
    authority: 'https://login.microsoftonline.com/common',
    redirectURI: process.env.REACT_APP_REDIRECTURI || 'https://uwp2-develop.g-portal.net/login',
    postLogoutRedirectUri:
      process.env.REACT_APP_REDIRECTURI || 'https://uwp2-develop.g-portal.net/login',
    // redirectURI: 'http://localhost:3000/login',
    // postLogoutRedirectUri: 'http://localhost:3000/login',
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message)
            return
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message)
            return
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message)
            return
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message)
            break
          default:
            break
        }
      },
    },
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
  //  scopes: ['User.Read'],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
