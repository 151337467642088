import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const Display = styled(Flex)(
  () => css`
    flex: 1 0;
    -webkit-overflow-scrolling: touch;
    z-index: 1015;
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `
)

export default Display
