import React from 'react'
import StyledNumberBubble from './styled/StyledNumberBubble'

const NumberBubble = ({ children }) => {
  return (
    <StyledNumberBubble>
      {children}
    </StyledNumberBubble>
  )
}

export default NumberBubble