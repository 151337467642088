import styled, { css } from 'styled-components'
import { Text } from 'rebass/styled-components'

const OnlineState = styled(Text)(
  (props) => css`
    text-transform: uppercase;
    color: ${props.theme.colors.warning};
    ${props.state === 'online' &&
    css`
      color: ${props.theme.colors.success};
    `}
    ${props.state === 'offline' &&
    css`
      color: ${props.theme.colors.danger};
    `}
  `
)

export default OnlineState
