import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'

import rootReducer from './reducers'

export const history = createBrowserHistory()

// export const history = { location: null }

const setupReduxStore = () => {
  const logger = createLogger({
    // predicate: (getState, action) =>
    //   // action.type === 'SET_CONTROLS' ||
    //   // action.type === 'RESET_CONTROLS' ||
    //   // action.type === 'UNSET_CONTROLS' ||
    //   action.type === 'message/SUCCESS',
    // collapsed: (getState, action, logEntry) => !logEntry.error,
    diff: true,
  })

  const middleware = [routerMiddleware(history), thunk, logger]
  // const middleware = [routerMiddleware(history), thunk]
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(...middleware))

    // Enable the Redux DevTools extension if available
    // / See more: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfiblj
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  return store
}

export default setupReduxStore
