import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Countdown from 'react-countdown'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Card, Flex, Image, Text } from 'rebass/styled-components'
import { updateServerOnlineState } from '../../redux/server/actions'
import { setControls, updateControls } from '../../redux/controls/actions'

import { initiateSocket, disconnectSocket, subscribeToSensors } from '../../libs/socket_helper'

import StatText from './components/StatText'
import Button from './styled/Button'
import Container from './styled/Container'
import Tag from './styled/Tag'
import OnlineState from './styled/OnlineState'
// import Marquee from './styled/Marquee'

import flair from '../../assets/serverCardFlair.svg'

import resource from './messages/en'
import { buttons, stoppedButtons } from './controls'

const ServerCard = ({ server, isSelected }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  i18n.addResources('en', 'servercard', resource)

  const {
    expire,
    ip_and_port: ipPortString,
    name,
    online,
    queryUrl,
    service_id: serviceId,
    time_till_deletion: timeTillDeletion,
    location,
  } = server

  const { location_region: locationRegion } = location
  const { websocket_url: websocketUrl } = locationRegion

  const [service, setService] = useState(serviceId)
  const [sensors, setSensors] = useState()
  const [socketUrl, setSocketUrl] = useState(websocketUrl)
  const [socket, setSocket] = useState()
  const [cpuLoad, setCpuLoad] = useState(0)
  const [ramLoad, setRamLoad] = useState({ used: 0, total: 0 })

  const [onlineState, setOnlineState] = useState()
  const [currentName, setCurrentName] = useState(name)

  const setButtons = useCallback(() => dispatch(setControls(buttons)), [dispatch])
  const setStoppedButtons = useCallback(() => dispatch(setControls(stoppedButtons)), [dispatch])
  const updateButtons = useCallback((obj) => dispatch(updateControls(obj)), [dispatch])

  useEffect(() => {
    setSocketUrl(websocketUrl)
  }, [websocketUrl])

  useEffect(() => {
    setCurrentName(name)
  }, [name])

  useEffect(() => {
    setOnlineState(online ? 'online' : 'offline')
    if (online !== 'online') {
      setCpuLoad(0)
      setRamLoad({ used: 0, total: 0 })
    }
  }, [online])

  useEffect(() => {
    if (serviceId) setService(serviceId)
  }, [serviceId])

  useEffect(() => {
    if (service && socketUrl !== 'undefined') setSocket(initiateSocket(service, socketUrl))
  }, [service, socketUrl])

  useEffect(() => {
    subscribeToSensors(socket, (obj) => {
      if (!obj) return
      setSensors(obj)
    })
    return () => {
      disconnectSocket()
    }
  }, [socket])

  useEffect(() => {
    if (sensors !== undefined && sensors.id === service) {
      if (sensors.type === 'sensors') {
        const { cpu } = sensors.data
        if (cpu === undefined) {
          setCpuLoad(0)
        } else {
          setCpuLoad(cpu.toFixed(2))
        }

        if (sensors.data.memory === undefined || sensors.data.memory.used === undefined) {
          setRamLoad({ used: 0, total: 0 })
        } else {
          setRamLoad({ used: sensors.data.memory.used || 0, total: sensors.data.memory.total || 0 })
        }
      }
      if (sensors.type === 'stateChanged') {
        switch (sensors.data.state) {
          case 1337:
            setOnlineState('loading')
            dispatch(updateServerOnlineState({ id: server.id, state: 'loading' }))
            break
          case 0:
            setOnlineState('preInstall')
            dispatch(updateServerOnlineState({ id: server.id, state: 'preInstall' }))
            break
          case 1:
            setOnlineState('install')
            dispatch(updateServerOnlineState({ id: server.id, state: 'install' }))
            break
          case 2:
            setOnlineState('suspended')
            dispatch(updateServerOnlineState({ id: server.id, state: 'suspended' }))
            break
          case 3:
            // setOnlineState('stopped')
            setOnlineState('offline')
            dispatch(updateServerOnlineState({ id: server.id, state: 'stopped' }))
            setCpuLoad(0)
            setRamLoad({ used: 0, total: 0 })
            if (isSelected) setStoppedButtons()
            break
          case 4:
            setOnlineState('stopping')
            dispatch(updateServerOnlineState({ id: server.id, state: 'stopping' }))
            break
          case 5:
            setOnlineState('starting')
            dispatch(updateServerOnlineState({ id: server.id, state: 'starting' }))
            break
          case 6:
            // setOnlineState('running')
            setOnlineState('online')
            dispatch(updateServerOnlineState({ id: server.id, state: 'running' }))
            if (isSelected) setButtons(buttons)
            break
          case 7:
            setOnlineState('updating')
            dispatch(updateServerOnlineState({ id: server.id, state: 'updating' }))
            break
          case 98:
            setOnlineState('removing')
            dispatch(updateServerOnlineState({ id: server.id, state: 'removing' }))
            break
          case 99:
            setOnlineState('maintenance')
            dispatch(updateServerOnlineState({ id: server.id, state: 'maintenance' }))
            break
          case 100:
            setOnlineState('unknown')
            dispatch(updateServerOnlineState({ id: server.id, state: 'unknown' }))
            break
          default:
            break
        }
      }
    }
  }, [
    sensors,
    dispatch,
    queryUrl,
    updateButtons,
    service,
    server.id,
    isSelected,
    setButtons,
    setStoppedButtons,
  ])

  return (
    <Card borderRadius={8} flexGrow={1} sx={{ display: 'flex' }}>
      <Flex flexGrow={1}>
        <Flex sx={{ position: 'relative', zIndex: 1001 }}>
          <Image
            src={server.product.images.cover}
            sx={{
              borderRadius: 8,
              objectFit: 'cover',
              minWidth: '180px',
              width: '180px',
            }}
          />
        </Flex>
        <Container
          direction="colum"
          width={[3 / 5, 4 / 5]}
          bg="primary"
          selected={isSelected}
          expired={timeTillDeletion?.days > 0}
        >
          <Flex
            bg="primary"
            flexGrow="1"
            sx={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}
          >
            <Image src={flair} sx={{ position: 'absolute', right: '30px', bottom: '20px' }} />
            {expire.days < 15 && !timeTillDeletion && (
              <Countdown
                date={expire.date}
                renderer={(data) => (
                  <Tag softReminder="soft">
                    {t('servercard:expiryKeys', {
                      d: data.days,
                      h: data.hours,
                      m: data.minutes,
                    })}
                    <span>{t('servercard:expiryText')}</span>
                  </Tag>
                )}
              />
            )}
            {timeTillDeletion && (
              <Countdown
                date={timeTillDeletion.date}
                renderer={(data) => (
                  <Tag softReminder="hard">
                    {t('servercard:expiryKeys', {
                      d: data.days,
                      h: data.hours,
                      m: data.minutes,
                    })}
                    <span>{t('servercard:deletionText')}</span>
                  </Tag>
                )}
              />
            )}
            <Box
              style={{
                minHeight: '200px',
                width: '100%',
                padding: '30px',
                margin: '20px 0',
                zIndex: 1,
              }}
            >
              {onlineState && (
                <OnlineState state={onlineState}>{t(`servercard:${onlineState}`)}</OnlineState>
              )}
              <Text variant="headlineServerCard" my="0" color="text">
                <span>{currentName && `${currentName}`}</span>
              </Text>
              <Text variant="headlineServerCardLight" my="0" color="text">
                <span>{server.product.name && `${server.product.name}`}</span>
              </Text>
              {timeTillDeletion && timeTillDeletion?.days ? (
                <Flex
                  alignItems="flex-start"
                  py="2"
                  flexDirection="column"
                  flexGrow={1}
                  flexBasis="100%"
                  flexShrink={0}
                >
                  {isSelected ? (
                    <>
                      <Text variant="lightWiden">
                        <Trans i18nKey="reactivateServer">
                          Press{' '}
                          <Button
                            sx={{
                              width: '36px',
                              height: '36px',
                              display: 'inline',
                            }}
                            color="#33ed3d"
                            bg="#262f37"
                          >
                            A
                          </Button>{' '}
                          if you want to <Text variant="inlineGreen">Reactivate</Text> your server.
                        </Trans>
                      </Text>
                      {server.product?.configurations[0]?.price && (
                        <Text variant="lightWiden">
                          {t('servercard:price', {
                            amount: server.product?.configurations[0]?.price,
                          })}
                        </Text>
                      )}
                    </>
                  ) : (
                    <Text variant="lightWiden">{t(`servercard:serverExpired`)}</Text>
                  )}
                </Flex>
              ) : (
                <Text>
                  <Flex pt="10px" flexWrap="wrap">
                    <Flex width={(1, 1 / 2)} justifyContent="flex-end" flexDirection="column">
                      <Text variant="serverCard" color="text">{`IP: ${ipPortString}`}</Text>
                    </Flex>
                  </Flex>
                  <Flex pt="10px">
                    <StatText valued kind="ram" stat={{ active: ramLoad }} />
                    <StatText percent kind="cpu" stat={{ active: cpuLoad, total: 100 }} />
                  </Flex>
                </Text>
              )}
            </Box>
          </Flex>
        </Container>
      </Flex>
    </Card>
  )
}

ServerCard.defaultProps = {
  isSelected: false,
}

ServerCard.propTypes = {
  server: PropTypes.shape({
    expire: PropTypes.shape({
      date: PropTypes.string,
      days: PropTypes.string,
      hours: PropTypes.string,
      minutes: PropTypes.string,
    }),
    time_till_deletion: PropTypes.shape({
      date: PropTypes.string,
      days: PropTypes.string,
      hours: PropTypes.string,
      minutes: PropTypes.string,
    }),
    ip_and_port: PropTypes.string,
    name: PropTypes.string,
    online: PropTypes.bool,
    queryUrl: PropTypes.string,
    service_id: PropTypes.number,
    id: PropTypes.number,
    product: PropTypes.shape({
      name: PropTypes.string,
      images: PropTypes.shape({
        logo: PropTypes.string,
        cover: PropTypes.string,
      }),
    }),
    location: PropTypes.shape({
      websocket_url: PropTypes.string,
    }),
  }).isRequired,
  isSelected: PropTypes.bool,
}

export default ServerCard
